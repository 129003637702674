import React from 'react';
import FilesWrapper from '../../Components/Files/FilesWrapper';

const Files = () => {
  return (
    <div className="wrapper">
      <div className="generic-screen content">
        <FilesWrapper />
      </div>
    </div>
  );
};

export default Files;
