import { create } from 'apisauce';
import { Auth } from '../Redux/reducers/auth';
import { store } from '../Redux/store';
import { getServer, getApiKey } from '../Utils/url';
import { Error } from '../Redux/reducers/error';
import { setQuery } from '../Utils/query';

export const token = () => store.getState().auth.token;
// Define the api
const API = create({
  baseURL: getServer(),
});
const monitor = response => {
  if (response.status === 401) {
    store.dispatch(Auth.authClear());
  }
  if (!response.ok) {
    if (response.status !== 401) {
      store.dispatch(Error.errorSet(response));
    }
  }
};

const getHeadersSite = () => {
  return {
    headers: { Authorization: `Bearer ${token()}`, 'X-From-Site': true },
  };
};

API.addMonitor(monitor);
export async function login(email, password) {
  return await API.post('/auth', { email, password, strategy: 'local' });
}
//Users
export const getUsers = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/users?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const findUserByNameOrEmail = async search =>
  API.get(
    `/users?$or[0][full_name][$options]=i&$or[0][full_name][$regex]=${search}&$or[1][email][$options]=i&$or[1][email][$regex]=${search}`,
    {},
    getHeadersSite()
  );
export const getUserById = async _id =>
  await API.get(`/users/${_id}`, {}, getHeadersSite());
export const createUser = async (body = {}) =>
  await API.post('/users', body, getHeadersSite());
export const updateUser = async (id, body = {}) =>
  await API.patch('/users/' + id, body, getHeadersSite());
//Roles
export const getRoles = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/roles?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );

//Forgot
export async function requestForgot(email) {
  return await API.post(
    '/forgot',
    { email },
    {
      headers: { 'x-api-key': getApiKey() },
    }
  );
}
//Multimedia
export const getPath = async ({ is_public, ...props }) =>
  await API.post(
    `/utils`,
    {
      action: 'list-directories',
      is_public,
      ...props,
    },
    getHeadersSite()
  );
export const getCustomPath = async custom_path =>
  await API.post(
    `/utils`,
    {
      action: 'list-directories',
      custom_path,
    },
    getHeadersSite()
  );
export const getPathByUser = async id =>
  await API.post(
    `/utils`,
    {
      action: 'list-directories',
      custom_path: id + '/',
    },
    getHeadersSite()
  );
export const createFolder = async (custom_path, body = {}) =>
  await API.post(
    `/utils`,
    {
      action: 'create-folder',
      custom_path,
      ...body,
    },
    getHeadersSite()
  );
export const deleteFolder = async path =>
  await API.post(
    `/utils`,
    {
      action: 'remove-folder',
      path,
    },
    getHeadersSite()
  );
export const findFiles = async obj =>
  await API.post(
    `/utils`,
    {
      action: 'find-files',
      ...obj,
    },
    getHeadersSite()
  );
export const deleteFile = async id =>
  await API.delete(`/multimedia/${id}`, {}, getHeadersSite());
export const updateFile = async (id, values) =>
  await API.patch(`/multimedia/${id}`, values, getHeadersSite());
export const downloadFile = async (path, fileName = 'file') => {
  let response = await fetch(
    `${getServer()}/upload?target=${path}&token=${token()}`,
    { method: 'GET' }
  );
  if (response.ok) {
    let a = document.createElement('a');
    let url = window.URL.createObjectURL(await response.blob());
    //Link styles
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    //Click link
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
};
export const getImageUrl = async url => {
  const tokenUser = await token();

  return `${getServer()}/upload?target=${url}&token=${tokenUser}`;
};
//Labels

export const getLabels = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/labels?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const createLabel = async (body = {}) =>
  await API.post('/labels', body, getHeadersSite());
export const updateLabel = async (id, body = {}) =>
  await API.patch('/labels/' + id, body, getHeadersSite());
export const deleteLabel = async _id =>
  await API.delete(`/labels/${_id}`, {}, getHeadersSite());

//Multimedia
export const getMedia = async id =>
  await API.get(`/multimedia/${id}`, {}, getHeadersSite());
//Brand
export const getBrands = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/brands?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const createBrand = async (body = {}) =>
  await API.post('/brands', body, getHeadersSite());
export const updateBrand = async (id, body = {}) =>
  await API.patch('/brands/' + id, body, getHeadersSite());
//Colors
export const getColors = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/colors?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const createColor = async (body = {}) =>
  await API.post('/colors', body, getHeadersSite());
export const updateColor = async (id, body = {}) =>
  await API.patch('/colors/' + id, body, getHeadersSite());
export const deleteColor = async id =>
  await API.delete(`/colors/${id}`, {}, getHeadersSite());
//Models
export const getModels = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/models?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const createModel = async (body = {}) =>
  await API.post('/models', body, getHeadersSite());
export const updateModel = async (id, body = {}) =>
  await API.patch('/models/' + id, body, getHeadersSite());
export const deleteModel = async id =>
  await API.delete(`/models/${id}`, {}, getHeadersSite());
//Fuels
export const getFuels = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/fuels?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const createFuel = async (body = {}) =>
  await API.post('/fuels', body, getHeadersSite());
export const updateFuel = async (id, body = {}) =>
  await API.patch('/fuels/' + id, body, getHeadersSite());
export const deleteFuel = async id =>
  await API.delete(`/fuels/${id}`, {}, getHeadersSite());
//Alerts
export const getAlertCategories = async () =>
  await API.get('/alert-categories', {}, getHeadersSite());
export const createAlert = async body =>
  await API.post('/alerts', body, getHeadersSite());
//Classes
export const getClasses = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/classes?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const createClass = async (body = {}) =>
  await API.post('/classes', body, getHeadersSite());
export const updateClass = async (id, body = {}) =>
  await API.patch('/classes/' + id, body, getHeadersSite());
export const deleteClass = async id =>
  await API.delete(`/classes/${id}`, {}, getHeadersSite());
//Companies
export const getCompanies = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/companies?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const createCompany = async (body = {}) =>
  await API.post('/companies', body, getHeadersSite());
export const updateCompany = async (id, body = {}) =>
  await API.patch('/companies/' + id, body, getHeadersSite());
export const deleteCompany = async id =>
  await API.delete(`/companies/${id}`, {}, getHeadersSite());
//Hives
export const getHives = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/hives?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const findHivesByName = async (name = '') =>
  await API.get(
    `/hives?$or[0][name][$regex]=${name}&$or[0][name][$options]=i`,
    {},
    getHeadersSite()
  );
export const getHive = async _id =>
  await API.get(`/hives/${_id}`, {}, getHeadersSite());
export const createHive = async (body = {}) =>
  await API.post('/hives', body, getHeadersSite());
export const updateHive = async (id, body = {}) =>
  await API.patch('/hives/' + id, body, getHeadersSite());
export const deleteHive = async id =>
  await API.delete(`/hives/${id}`, {}, getHeadersSite());
//Swarms
export const getSwarm = async _id =>
  await API.get(`/swarms/${_id}`, {}, getHeadersSite());
export const getMembers = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/members?status=1&$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const deleteMember = async _id =>
  await API.delete(`/members/${_id}`, {}, getHeadersSite());
export const findSwarmsByName = async (name = '') =>
  await API.get(
    `/swarms?$or[0][name][$regex]=${name}&$or[0][name][$options]=i`,
    {},
    getHeadersSite()
  );
export const getSwarms = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/swarms?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const createSwarm = async (body = {}) =>
  await API.post('/swarms', body, getHeadersSite());
export const updateSwarm = async (id, body = {}) =>
  await API.patch('/swarms/' + id, body, getHeadersSite());
export const deleteSwarm = async id =>
  await API.delete(`/swarms/${id}`, {}, getHeadersSite());
//Bees
export const getBees = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/bees?status=1&$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const createBee = async (body = {}) =>
  await API.post('/bees', body, getHeadersSite());
export const updateBee = async (id, body = {}) =>
  await API.patch('/bees/' + id, body, getHeadersSite());
export const deleteBee = async id =>
  await API.delete(`/bees/${id}`, {}, getHeadersSite());

//Configs

export const getConfig = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/configs?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const createConfig = async (body = {}) =>
  await API.post('/configs', body, getHeadersSite());
export const updateConfig = async (id, body = {}) =>
  await API.patch('/configs/' + id, body, getHeadersSite());
export const deleteConfig = async _id =>
  await API.delete(`/configs/${_id}`, {}, getHeadersSite());
//Categories
export const getCategories = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/categories?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const createCategory = async (body = {}) =>
  await API.post('/categories', body, getHeadersSite());
export const updateCategory = async (id, body = {}) =>
  await API.patch('/categories/' + id, body, getHeadersSite());
export const deleteCategory = async id =>
  await API.delete(`/categories/${id}`, {}, getHeadersSite());
//Permissions
export const getPermissions = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/permissions-schema?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    getHeadersSite()
  );
export const createPermission = async (body = {}) =>
  await API.post('/permissions-schema', body, getHeadersSite());
export const updatePermission = async (id, body = {}) =>
  await API.patch('/permissions-schema/' + id, body, getHeadersSite());
export const deletePermission = async id =>
  await API.delete(`/permissions-schema/${id}`, {}, getHeadersSite());
//Admin operations
export const deleteGroup = async payload =>
  await API.post('/operation-admon', { ...payload }, getHeadersSite());
