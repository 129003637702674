import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'antd';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../Hooks/Auth.hook';
import { useI18n } from '../../Hooks/i18n.hook';

const Logout = () => {
  const [, , logOut] = useAuth();
  const [, label] = useI18n();
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <>
      <Modal
        title={label('LOGOUT_QUESTION')}
        visible={modalVisible}
        onOk={logOut}
        onCancel={() => setModalVisible(false)}
        okText={label('CONFIRM')}
        cancelText={label('CANCEL')}
      />
      <div className="logout-trigger">
        <FontAwesomeIcon
          icon={faSignOutAlt}
          onClick={() => setModalVisible(true)}
          style={{ cursor: 'pointer' }}
        >
          Cerrar sesión
        </FontAwesomeIcon>
      </div>
    </>
  );
};

export default Logout;
