import { Avatar, Form, List, Modal, Tabs } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useI18n } from '../../Hooks/i18n.hook';
import { useGeocode } from '../../Hooks/Geocode.hook';
import { createUser, updateUser } from '../../Services/API';
import { formatDate, paginate } from '../../Utils/helpers';
import Image from '../Antd/Image';
import UserForm from '../Form/User.form';

import iconHive from '../../Assets/img/icon_hive.png';
import iconSwarm from '../../Assets/img/icon_swarm.png';

const { TabPane } = Tabs;
const tabKeys = ['userForm', 'userGroups'];

const UserModal = ({ user, updater, screen = '', ...props }) => {
  const [, l] = useI18n();
  const { auth } = useSelector(state => ({ auth: state.auth }));
  const [formRef] = Form.useForm();
  const [tabKey, setTabKey] = useState(tabKeys[0]);
  const [loading, setLoading] = useState(false);
  const [skipPagination, setSkippedPagination] = useState(1);
  const [limitPagination] = useState(10);
  const [address, setCoords] = useGeocode();

  useEffect(() => {
    if (user?._id) {
      formRef.setFieldsValue(user);
      formRef.setFields([
        {
          name: 'password',
          value: '',
        },
      ]);
    } else {
      formRef.resetFields();
    }
  }, [user]);

  const _submitForm = async values => {
    setLoading(true);
    let response;
    if (user?._id) {
      if (!values.password) {
        delete values.password;
        delete values.rpassword;
      }
      delete values.location;
      response = await updateUser(user._id, values);
    } else {
      response = await createUser(values);
    }
    if (response.ok) {
      updater();
    }
    setLoading(false);
  };

  const onChangeTab = React.useCallback(sKey => {
    setTabKey(sKey);
  }, []);

  useEffect(() => {
    if (user?.last_position?.coordinates) {
      const lat = user?.last_position?.coordinates[1];
      const lng = user?.last_position?.coordinates[0];
      setCoords({ lat, lng });
    }
  }, [user?.last_position]);

  useEffect(() => {
    if (address) {
      formRef.setFields([
        {
          name: 'location',
          value: address,
        },
      ]);
    }
  }, [address]);

  useEffect(() => {
    if (user?.updated_at) {
      const formattedDate = `${formatDate(
        new Date(user?.updated_at)
      )} ${new Date(user?.updated_at).toLocaleString('en-US', {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric',
      })}`;

      formRef.setFields([
        {
          name: 'location',
          value: `${address} -  ${formattedDate}`,
        },
      ]);
    }
  }, [user?.updated_at]);

  return (
    <Modal
      title={
        user?._id
          ? auth?.user?._id === user?._id
            ? l('UPDATE_USER')
            : l('USER_DETAIL')
          : l('ADD_NEW_USER')
      }
      centered
      okButtonProps={{ loading }}
      onOk={() => formRef.submit()}
      {...props}
    >
      <Tabs defaultActiveKey={tabKey} onChange={onChangeTab}>
        <TabPane tab={l('GENERAL_INFO')} key={tabKeys[0]}>
          <UserForm
            formRef={formRef}
            address={address}
            onSubmit={_submitForm}
            edit={user?._id}
            screen={screen}
          />
        </TabPane>
        {user.groups?.length > 0 && (
          <TabPane tab={l('GROUPS')} key={tabKeys[1]}>
            <List
              className="user-groups-list"
              itemLayout="horizontal"
              dataSource={paginate(
                user.groups,
                limitPagination,
                skipPagination
              )}
              pagination={{
                defaultCurrent: 1,
                total: user.groups.length,
                onChange: e => setSkippedPagination(e),
                pageSizeOptions: [10],
              }}
              renderItem={oGroup => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      oGroup?.image ? (
                        <Avatar size={40} src={<Image src={oGroup?.image} />} />
                      ) : oGroup?.type === 'swarms' ? (
                        <img width={40} src={iconSwarm} />
                      ) : (
                        <img width={40} src={iconHive} />
                      )
                    }
                    title={oGroup?.name}
                    description={oGroup?.description}
                  />
                </List.Item>
              )}
            />
          </TabPane>
        )}
      </Tabs>
    </Modal>
  );
};

UserModal.propTypes = {
  user: PropTypes.object,
  updater: PropTypes.func,
  screen: PropTypes.string,
};

export default UserModal;
