import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { useI18n } from '../../Hooks/i18n.hook';

const FolderForm = ({ formRef, onSubmit, label, ...props }) => {
  const [, l] = useI18n();
  return (
    <Form
      form={formRef}
      onFinish={onSubmit}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      {...props}
    >
      <Form.Item
        label={label || l('FOLDER_NAME')}
        name="name"
        labelAlign="left"
      >
        <Input />
      </Form.Item>
    </Form>
  );
};
FolderForm.propTypes = {
  formRef: PropTypes.any,
  onSubmit: PropTypes.func,
  label: PropTypes.string,
};
export default FolderForm;
