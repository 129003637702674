import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { Routes } from '../../Share/Routes';
//Hooks
import { useNavigation } from '../../Hooks/Nav.hook';
import { useI18n } from '../../Hooks/i18n.hook';
import { useAuth } from '../../Hooks/Auth.hook';

const MenuDashboard = ({ ...props }) => {
  const [, getLabel] = useI18n();
  const [auth] = useAuth();
  const [{ pathname }, nav] = useNavigation();

  const _getActive = () => {
    return pathname.replace('/dashboard', '') || '/';
  };
  const _renderRoutes = () => {
    const routes =
      Routes.filter(
        item => !(item.slug === 'users' && auth.role.rol === 'bees')
      ) || [];

    return routes.map(({ title, childs: children, slug, path, icon }) => {
      return children.length === 0 ? (
        <Menu.Item
          key={path}
          onClick={() => nav(`/dashboard${path}`)}
          icon={icon}
        >
          {getLabel(title)}
        </Menu.Item>
      ) : (
        <Menu.SubMenu title={getLabel(title)} key={slug} icon={icon}>
          {children.map(data => {
            return (
              <Menu.Item
                key={path + data.path}
                onClick={() => nav(`/dashboard${path}${data.path}`)}
                icon={data?.icon || icon}
              >
                {getLabel(data.title)}
              </Menu.Item>
            );
          })}
        </Menu.SubMenu>
      );
    });
  };

  return (
    <Menu
      selectedKeys={[_getActive()]}
      mode="inline"
      theme="dark"
      style={{ width: 'auto' }}
      {...props}
    >
      {_renderRoutes()}
    </Menu>
  );
};

MenuDashboard.propTypes = {
  drawerOptions: PropTypes.shape({
    visible: PropTypes.bool,
    handler: PropTypes.func,
  }),
};

export default MenuDashboard;
