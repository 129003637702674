import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select } from 'antd';
//Hooks
import { useFetchBrands } from '../../Hooks/Brands.hook';
import { useI18n } from '../../Hooks/i18n.hook';

const ModelForm = ({ formRef, onSubmit, ...props }) => {
  const [data, loading] = useFetchBrands();
  const [, l] = useI18n();
  return (
    <Form form={formRef} onFinish={onSubmit} layout="vertical" {...props}>
      <Form.Item
        label={l('NAME')}
        name="name"
        rules={[{ required: true, message: l('_fieldRequired') }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={l('BRAND')}
        name="brand_id"
        rules={[{ required: true, message: l('_fieldRequired') }]}
      >
        <Select loading={loading}>
          {data.data.map(brand => (
            <Select.Option value={brand._id} key={brand._id}>
              {brand.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={l('STATUS')} name="status">
        <Select>
          <Select.Option value={1}>{l('ACTIVE')}</Select.Option>
          <Select.Option value={0}>{l('INACTIVE')}</Select.Option>
        </Select>
      </Form.Item>
    </Form>
  );
};
ModelForm.propTypes = {
  formRef: PropTypes.any,
  onSubmit: PropTypes.func,
};
export default ModelForm;
