import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Tabs } from 'antd';
import JoditEditor from 'jodit-react';
import { useI18n } from '../../Hooks/i18n.hook';

const { TabPane } = Tabs;

const LabelForm = ({ formRef, onSubmit, ...props }) => {
  const [, l] = useI18n();
  const config = { height: 500, placeholder: 'type here...' };
  return (
    <Form
      form={formRef}
      onFinish={onSubmit}
      labelCol={{ span: 8 }}
      initialValues={{ country: '*', language: '*', text: '' }}
      {...props}
    >
      <Tabs>
        <TabPane tab={l('TAG_DETAIL')} key={1}>
          <Form.Item
            label={l('SLUG')}
            name="slug"
            labelAlign="left"
            rules={[{ required: true, message: l('_fieldRequired') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={l('SECTION')}
            name="section"
            labelAlign="left"
            rules={[{ required: true, message: l('_fieldRequired') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={l('LANGUAGE')} name="language" labelAlign="left">
            <Input disabled />
          </Form.Item>
          <Form.Item label={l('COUNTRY')} name="country" labelAlign="left">
            <Input disabled />
          </Form.Item>
          <Form.Item name="text" labelAlign="left" style={{ display: 'none' }}>
            <Input disabled />
          </Form.Item>
        </TabPane>
        <TabPane tab={l('TEXT')} key={2}>
          <JoditEditor
            value={formRef.getFieldValue('text')}
            config={config}
            onChange={e => {
              formRef.setFieldsValue({ text: e });
            }}
          />
        </TabPane>
      </Tabs>
    </Form>
  );
};
LabelForm.propTypes = {
  onSubmit: PropTypes.func,
  formRef: PropTypes.any,
  loading: PropTypes.bool,
};
export default LabelForm;
