import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'antd';
import PropTypes from 'prop-types';
import BrandForm from '../Form/Brand.form';
import { createBrand, updateBrand } from '../../Services/API';
import { useI18n } from '../../Hooks/i18n.hook';

const BrandModal = ({ item, updater, ...props }) => {
  const [, l] = useI18n();
  const [formRef] = Form.useForm();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (item?._id) {
      formRef.setFieldsValue(item);
    } else {
      formRef.resetFields();
    }
  }, [item]);
  const _submitForm = async values => {
    setLoading(true);
    let response;
    if (item?._id) {
      response = await updateBrand(item._id, values);
    } else {
      response = await createBrand(values);
    }
    if (response.ok) {
      updater();
    }
    setLoading(false);
  };
  return (
    <Modal
      title={item?._id ? l('UPDATE_BRAND') : l('ADD_NEW_BRAND')}
      centered
      okButtonProps={{ loading }}
      onOk={() => formRef.submit()}
      destroyOnClose
      {...props}
    >
      <BrandForm formRef={formRef} onSubmit={_submitForm} />
    </Modal>
  );
};
BrandModal.propTypes = {
  item: PropTypes.object,
  updater: PropTypes.func,
};
export default BrandModal;
