import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { downloadFile } from '../../Services/API';
import { useI18n } from '../../Hooks/i18n.hook';

const LoadingButton = ({ path, fileName }) => {
  const [_loading, setLoading] = useState(false);
  const [, l] = useI18n();
  async function fetchFile() {
    setLoading(true);
    await downloadFile(path, fileName);
    setLoading(false);
  }
  return (
    <Button
      type="link"
      key="list-loadmore-edit"
      disabled={_loading}
      onClick={fetchFile}
    >
      {_loading ? l('DOWNLOADING') : l('DOWNLOAD')}
    </Button>
  );
};
LoadingButton.propTypes = {
  path: PropTypes.string,
  fileName: PropTypes.string,
};
export default LoadingButton;
