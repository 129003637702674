import Intl from 'intl';
import 'intl/locale-data/jsonp/en-US';

export const paginate = (array, limit, skip) => {
  if (skip > 0 && limit > 0) {
    return array.slice((skip - 1) * limit, skip * limit);
  }
  return [];
};

export const removeAccents = str => {
  if (str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  } else {
    return str;
  }
};

export const returnNoRepeatedBees = (beesBySearch, membersBySearch) => {
  const membersAndBees = [
    ...(beesBySearch || []),
    ...(membersBySearch || []),
  ].reduce((aBees, el) => {
    if (!aBees.find(el2 => el2?.user_id === el?.user_id)) {
      aBees.push(el);
    }
    return aBees;
  }, []);
  return membersAndBees;
};

export const findBeeOrGroup = (searchValue, userGroups, bees) => {
  const search = removeAccents(searchValue.trim());
  let groupsBySearch = [];
  let beesBySearch = [];
  let membersBySearch = [];
  groupsBySearch = userGroups.filter(
    oGroup =>
      removeAccents(oGroup.name?.toLowerCase()).includes(
        search.toLowerCase()
      ) && oGroup.status === 1
  );
  beesBySearch = bees.filter(
    oBees =>
      (removeAccents(oBees.user?.display_name?.toLowerCase() || '').includes(
        search.toLowerCase()
      ) ||
        removeAccents(oBees.user?.full_name?.toLowerCase() || '')?.includes(
          search.toLowerCase()
        )) &&
      oBees.status === 1
  );
  if (!beesBySearch) {
    beesBySearch = [];
  }
  const membersAndBees = returnNoRepeatedBees(beesBySearch, membersBySearch);
  return [groupsBySearch, membersAndBees];
};

export const formatDate = date => {
  let ye = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
  }).format(date);
  let mo = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
  }).format(date);
  let da = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
  }).format(date);

  return `${da}/${mo}/${ye}`;
};

export const noRepeatedBees = bees => {
  const notRepeated = bees.reduce((aBees, el) => {
    if (!aBees.find(bee => bee.user_id === el.user_id)) {
      aBees.push(el);
    }
    return aBees;
  }, []);
  return notRepeated;
};
