import { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { getGoogleApiKey } from '../Utils/url';

export const useGeocode = () => {
  const [coords, setCoords] = useState({
    lat: undefined,
    lng: undefined,
  });
  const [address, setAddress] = useState('');

  useEffect(() => {
    Geocode.setApiKey(getGoogleApiKey());
    Geocode.enableDebug(true);
    Geocode.setLocationType('ROOFTOP');
  }, []);

  useEffect(() => {
    if (coords?.lat && coords?.lng) {
      Geocode.fromLatLng(coords.lat, coords.lng).then(
        response => {
          const geoAddress = response.results[0].formatted_address;
          setAddress(geoAddress);
        },
        error => {
          console.warn(
            `Failed to get position address ${coords?.lat}, ${
              coords?.lng
            }. Error: ${error.message || JSON.stringify(error)}`
          );
        }
      );
    }
  }, [coords]);

  return [address, setCoords];
};
