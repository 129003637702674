import {
  faHome,
  faObjectGroup,
  faUserFriends,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const Routes = [
  {
    title: 'HOME',
    slug: 'home',
    path: '/',
    icon: <FontAwesomeIcon icon={faHome} className="anticon" />,
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'GROUPS',
    slug: 'groups',
    path: '/groups',
    icon: <FontAwesomeIcon icon={faObjectGroup} className="anticon" />,
    childs: [
      {
        title: 'HIVES',
        slug: 'hives',
        path: '/hives',
        icon: <FontAwesomeIcon icon={faUserFriends} className="anticon" />,
      },
      {
        title: 'SWARMS',
        slug: 'swarms',
        path: '/swarms',
        icon: <FontAwesomeIcon icon={faUsers} className="anticon" />,
      },
    ],
  },
  {
    title: 'USERS',
    slug: 'users',
    path: '/users',
    childs: [],
    icon: <FontAwesomeIcon icon={faUsers} className="anticon" />,
  },
];
