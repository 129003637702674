import React, { useEffect, useState } from 'react';
import immutable from 'seamless-immutable';
import {
  Button,
  Col,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Skeleton,
} from 'antd';
import { ChromePicker } from 'react-color';

import { getConfig, updateConfig } from '../../Services/API';
import { useI18n } from '../../Hooks/i18n.hook';

const GeneralView = () => {
  const [data, setData] = useState(immutable([]));
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [lang, l, setLanguage] = useI18n();
  //Change page title
  useEffect(() => {
    async function get() {
      setLoading(true);
      let response = await getConfig();
      if (response.ok) {
        setData(immutable(response.data.data));
      }
      setLoading(false);
    }
    get();
    document.title = 'Settings | Nordos Dashboard';
    return () => {
      document.title = 'Nordos Dashboard';
    };
  }, []);

  const _handleSave = async () => {
    let blnError = false;
    setBtnLoading(true);
    for (let { _id, elements } of data) {
      let response = await updateConfig(_id, {
        elements,
      });

      if (!response.ok) {
        message.error(l('_ERROR_SAVE_CONFIG'));
        blnError = true;
        break;
      }
    }
    setBtnLoading(false);
    if (!blnError) {
      message.success(l('DONE'));
    }
  };

  const _handleChangeValue = (value, index) => {
    setData(data.updateIn(index, row => row.merge({ value })));
  };

  const _renderInput = (node, index) => {
    switch (node.type) {
      case 'select':
        return (
          <Select
            value={node.value}
            style={{ width: '100%' }}
            onChange={e => _handleChangeValue(e, index)}
          >
            {node?.options?.map((item, key) => (
              <Select.Option key={key} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        );
      case 'number':
        return (
          <InputNumber
            min={0}
            style={{ width: '100%' }}
            value={node.value}
            onChange={e => _handleChangeValue(e, index)}
          />
        );
      case 'color':
        return (
          <ChromePicker
            color={node.value}
            onChange={e => _handleChangeValue(e.hex, index)}
          />
        );
      default:
        return (
          <Input
            style={{ width: '100%' }}
            value={node.value}
            onChange={e => _handleChangeValue(e, index)}
          />
        );
    }
  };

  const _renderElements = (elements, index) => {
    return (
      <Row gutter={[30, 30]}>
        {elements.map((element, i) => (
          <Col key={element.slug} span={24}>
            <h4>{l(`_${element.slug}-title`)}</h4>
            {_renderInput(element, [...index, i])}
            {element.description && (
              <small>{l(`_${element.slug}-description`)}</small>
            )}
          </Col>
        ))}
      </Row>
    );
  };

  const _renderContent = () => {
    if (loading) {
      return <Skeleton active />;
    }

    return (
      <Row gutter={[30, 30]}>
        {data.map((row, i) => (
          <Col key={row._id} xs={24} xl={12}>
            <h1>{l(`_${row.slug}`)}</h1>
            {row.slug === 'general' && (
              <Row gutter={[30, 30]}>
                <Col span={24}>
                  <h4>{l('CLIENT_LANGUAGE')}</h4>
                  <Select
                    style={{ width: '100%' }}
                    value={lang}
                    onChange={e => setLanguage(e)}
                  >
                    <Select.Option value="tc">TC</Select.Option>
                    <Select.Option value="en">EN</Select.Option>
                  </Select>
                </Col>
              </Row>
            )}
            {_renderElements(row.elements, [i, 'elements'])}
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <div className="container" style={{ height: '100%' }}>
      <Row justify="end">
        <Col>
          <Button type="primary" onClick={_handleSave} loading={btnLoading}>
            {l('SAVE')}
          </Button>
        </Col>
      </Row>
      {_renderContent()}
    </div>
  );
};

export default GeneralView;
