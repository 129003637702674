import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import SubRouter from '../SubRouter';
import ErrorFallback from './ErrorFallback';
import HeaderDashboard from './Header';

import { useNavigation } from '../../Hooks/Nav.hook';

const DefaultContainer = () => {
  const [location] = useNavigation();
  const routeName = location?.pathname.replace(/\//g, '') || undefined;
  let classStr = 'default-container';

  if (routeName) {
    classStr += ' page-' + routeName;
  }

  return (
    <div className={classStr}>
      <HeaderDashboard />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <SubRouter />
      </ErrorBoundary>
    </div>
  );
};

export default DefaultContainer;
