/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Modal,
  Form,
  Tabs,
  List,
  Avatar,
  Col,
  Row,
  Input,
  Button,
} from 'antd';
import Image from './../Antd/Image';
import PropTypes from 'prop-types';
import { deleteBee, deleteMember, getHive, getSwarm } from '../../Services/API';
import { useI18n } from './../../Hooks/i18n.hook';
import DefaultAvatar from '../../Assets/img/avatar-default.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { paginate } from '../../Utils/helpers';

const { Search } = Input;

const CreationModal = ({
  item,
  updater,
  apiFn = {
    updateRecord: async () => ({}),
    createRecord: async () => ({}),
    onSuccess: async () => ({}),
  },
  RecordForm,
  recordName = 'item',
  module,
  title = '',
  viewOnly = false,
  showMembers = true,
  description = '',
  ...props
}) => {
  const tabKeys = ['hiveForm', 'membersList'];
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [beeIdToDelete, setBeeIdToDelete] = useState(undefined);
  const [formRef] = Form.useForm();
  const { TabPane } = Tabs;
  const [, l] = useI18n();
  const [members, setMembers] = useState([]);
  const [tabKey, setTabKey] = useState(tabKeys[0]);
  const [loading, setLoading] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [deleteErrMsg, setDeleteErrMsg] = useState('');
  const [search, setSearch] = useState('');
  const [searchedPressed, setSearchedPressed] = useState(false);
  const [searchedMembers, setSearchedMembers] = useState([]);
  const [skipPagination, setSkippedPagination] = useState(1);
  const [limitPagination] = useState(10);

  useEffect(() => {
    if (item?._id) {
      formRef.setFieldsValue(item);
      if (module === 'hive') {
        getHiveMembers(item?._id);
      } else if (module === 'swarm') {
        getSwarmsMembers(item?._id);
      }
    } else {
      formRef.resetFields();
    }
  }, [item]);

  const _submitForm = async values => {
    setLoading(true);
    let response;
    if (item?._id) {
      response = await apiFn.updateRecord(item._id, values);
    } else {
      response = await apiFn.createRecord(values);
    }
    if (response?.ok) {
      if (apiFn.onSuccess) {
        await apiFn.onSuccess();
      }
      await updater();
    }
    setLoading(false);
  };

  const onChangeTab = React.useCallback(sKey => {
    setTabKey(sKey);
  }, []);

  const getHiveMembers = React.useCallback(async _id => {
    const oResponse = await getHive(_id);
    if (oResponse.data?.bees) {
      setMembers(oResponse.data.bees);
    } else {
      setMembers([]);
    }
  }, []);

  const getSwarmsMembers = React.useCallback(async _id => {
    const oResponse = await getSwarm(_id);
    if (oResponse.data?.members) {
      setMembers(oResponse.data.members);
    } else {
      setMembers([]);
    }
  }, []);

  const deleteBeeAction = React.useCallback(async () => {
    if (isOwner) {
      setDeleteErrMsg('No se puede eliminar al dueño del grupo');
      setTimeout(() => {
        setDeleteErrMsg('');
      }, 5000);
      return;
    }
    setLoadingDelete(true);
    if (module === 'hive') {
      const oResponse = await deleteBee(beeIdToDelete);
      if (oResponse.ok) {
        getHiveMembers(item?._id);
        setLoadingDelete(false);
      }
    } else {
      const oResponse = await deleteMember(beeIdToDelete);
      if (oResponse.ok) {
        getSwarmsMembers(item?._id);
        setLoadingDelete(false);
      }
    }
    setShowDeleteModal(false);
  }, [beeIdToDelete]);

  const onSearch = React.useCallback(async () => {
    if (search) {
      setSearchedPressed(true);
      const aFindedMembers = members.filter(oMember =>
        oMember.user?.full_name.includes(search)
      );
      if (aFindedMembers) {
        setSearchedMembers(aFindedMembers);
      } else {
        setSearchedMembers([]);
      }
    }
  }, [search, members]);

  React.useEffect(() => {
    if (!search) {
      setSearchedPressed(false);
    }
  }, [search]);

  const canShowMember = () =>
    showMembers && !viewOnly && members.length && members[0]?.user?.full_name;

  return (
    <>
      <Modal
        title={
          title ||
          (item?._id
            ? viewOnly
              ? `${l('DETAIL_OF')} ${recordName}`
              : `${l('UPDATE')} ${recordName}`
            : `${l('ADD_NEW')} ${recordName}`)
        }
        centered
        zIndex={1}
        okButtonProps={{ loading }}
        destroyOnClose
        footer={[
          <Button key="back" onClick={props?.onCancel}>
            {viewOnly ? l('CLOSE') : l('CANCEL')}
          </Button>,
          !viewOnly && (
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => formRef.submit()}
            >
              {item?._id ? l('UPDATE') : l('ADD')}
            </Button>
          ),
        ]}
        {...props}
      >
        <Tabs defaultActiveKey={tabKey} onChange={onChangeTab}>
          <TabPane tab={l('GENERAL_INFO')} key={tabKeys[0]}>
            <RecordForm
              formRef={formRef}
              onSubmit={_submitForm}
              edit={!viewOnly}
            />
            {description && (
              <Row>
                <Col span={24}>
                  <Alert message={description} showIcon type="warning" />
                </Col>
              </Row>
            )}
          </TabPane>
          {canShowMember() && (
            <TabPane tab={l('MEMBERS')} key={tabKeys[1]}>
              <Row>
                <Col span={24}>
                  <Search
                    placeholder={l('GROUP_NAME_MEMBERS')}
                    size="large"
                    onChange={e => setSearch(e.target.value)}
                    onSearch={onSearch}
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
              <List
                itemLayout="horizontal"
                dataSource={
                  search && searchedPressed
                    ? searchedMembers
                    : paginate(members, limitPagination, skipPagination)
                }
                pagination={{
                  defaultCurrent: 1,
                  total: members.length,
                  onChange: e => setSkippedPagination(e),
                  pageSizeOptions: [10],
                }}
                renderItem={member => (
                  <List.Item
                    actions={[
                      !viewOnly && (
                        <FontAwesomeIcon
                          key="icon-delete"
                          icon={faTrash}
                          color="red"
                          className="danger"
                          size="lg"
                          onClick={() => {
                            setIsOwner(member.is_owner);
                            setShowDeleteModal(true);
                            setBeeIdToDelete(member._id);
                          }}
                        />
                      ),
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          size={40}
                          src={
                            member.user?.photo ? (
                              <Image
                                src={
                                  member.user?.photo
                                    ? member.user?.photo
                                    : DefaultAvatar
                                }
                              />
                            ) : (
                              DefaultAvatar
                            )
                          }
                        />
                      }
                      title={<label>{member.user?.full_name}</label>}
                      description={
                        module === 'swarm'
                          ? member.role === 'admin'
                            ? `Rol: ${member.role}`
                            : `Tipo: ${member.type}`
                          : `Rol: ${member.role}`
                      }
                    />
                  </List.Item>
                )}
              />
            </TabPane>
          )}
        </Tabs>
      </Modal>
      <Modal
        title={l('MODAL_DELETE_TITLE')}
        visible={showDeleteModal}
        onOk={deleteBeeAction}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        centered
        confirmLoading={loadingDelete}
      >
        {deleteErrMsg === '' ? (
          <p>{l('SURE_TO_REMOVE_BEE')}</p>
        ) : (
          <p>{deleteErrMsg}</p>
        )}
      </Modal>
    </>
  );
};
CreationModal.propTypes = {
  item: PropTypes.object,
  updater: PropTypes.func.isRequired,
  apiFn: PropTypes.shape({
    createRecord: PropTypes.func.isRequired,
    updateRecord: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
  }),
  RecordForm: PropTypes.any.isRequired,
  recordName: PropTypes.string.isRequired,
  module: PropTypes.string,
  title: PropTypes.string,
  viewOnly: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  showMembers: PropTypes.bool,
  description: PropTypes.string,
};
export default CreationModal;
