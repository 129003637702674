import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faPlus,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Row, Popover } from 'antd';
import DynamicTable from '../../Components/Data/Table';
import PermissionForm from '../../Components/Form/Permission.form';
import CreationModal from '../../Components/Data/CreationModal';

//Hooks
import { PermissionColumn, useColumn } from '../../Hooks/Columns.hook';
import { useFetchPermissions } from '../../Hooks/Permissions.hook';
import { useI18n } from '../../Hooks/i18n.hook';
//Services
import { updatePermission, createPermission } from '../../Services/API';

const PermissionScreen = () => {
  const [, l] = useI18n();
  const [, getColumn] = useColumn(PermissionColumn);
  const [permissions, loading, , change, update] = useFetchPermissions();
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const columns = [
    getColumn('field'),
    getColumn('entity'),
    getColumn('index'),
    {
      title: '',
      dataIndex: '_id',
      render(data, row) {
        const [triggerVisible, setTriggerVisible] = useState(false);
        return (
          <Popover
            trigger="click"
            onVisibleChange={e => {
              setTriggerVisible(e);
            }}
            visible={triggerVisible}
            content={
              <div className="popover-common">
                <ul>
                  <li
                    onClick={() => {
                      setSelectedItem(row);
                      setModal(true);
                      setTriggerVisible(false);
                    }}
                  >
                    {l('EDIT')}
                    <FontAwesomeIcon icon={faPencilAlt} className="anticon" />
                  </li>
                </ul>
              </div>
            }
            placement="bottom"
          >
            <div style={{ width: 30 }}>
              <FontAwesomeIcon icon={faEllipsisV} className="anticon trigger" />
            </div>
          </Popover>
        );
      },
    },
  ];

  return (
    <div className="wrapper">
      <div className="users-screen content">
        <CreationModal
          visible={modal}
          apiFn={{
            createRecord: createPermission,
            updateRecord: updatePermission,
          }}
          recordName={l('PERMISSION')}
          RecordForm={PermissionForm}
          onCancel={() => {
            setModal(false);
            setSelectedItem({});
          }}
          okText={selectedItem?._id ? l('UPDATE') : l('ADD')}
          item={selectedItem}
          updater={() => {
            update();
            setModal(false);
            setSelectedItem({});
          }}
        />
        <Row justify="space-between" className="title">
          <Col>
            <h3>
              {permissions.total} {l('PERMISSIONS')}
            </h3>
          </Col>
          <Col>
            <Button
              onClick={() => setModal(true)}
              icon={<FontAwesomeIcon icon={faPlus} className="anticon" />}
            >
              {l('ADD_NEW_PERMISSION')}
            </Button>
          </Col>
        </Row>
        <div className="dynamic-table">
          <DynamicTable
            columns={columns}
            dataSource={permissions.data}
            loading={loading}
            rowKey={e => e._id}
            pagination={{
              total: permissions.total,
              current: permissions.params.skip / 10 + 1,
              onChange: e => change(permissions.params.queries, (e - 1) * 10),
              pageSizeOptions: [10],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PermissionScreen;
