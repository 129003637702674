import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import fileSize from 'filesize';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  Input,
  Modal,
  Upload as UploadAntd,
  message,
  List,
  Form,
  Breadcrumb,
  Skeleton,
  Tabs,
  Checkbox,
  Radio,
} from 'antd';
import immutable from 'seamless-immutable';
import { Upload, Trash, FolderPlus, Edit } from 'react-feather';
import {
  InboxOutlined,
  HomeOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import FileIcon from './FileIcon';
//Utils
import { getServer } from '../../Utils/url';
import { getFileType, naturalSort } from '../../Utils/parse';
//Forms
import FolderForm from '../Form/Folder.form';
import LoadingButton from './LoadingButton';
import PreviewButton from './PreviewButton';

//Hooks
import { useAuth } from '../../Hooks/Auth.hook';
import { useFetchUserFilesPath } from '../../Hooks/Files.hook';
import {
  createFolder,
  deleteFile,
  deleteFolder,
  updateFile,
} from '../../Services/API';
import { useI18n } from '../../Hooks/i18n.hook';

const { Dragger } = UploadAntd;
const { TabPane } = Tabs;

let timeout = null;
const _getFileType = (name = '') => {
  let names = name.split('.');
  return names[names.length - 1];
};

const _parseRelativePath = (fileManager, values, key) =>
  fileManager.currentPath.replace(`nordos/${key}/`, '') + values.name + '/';

const FilesWrapper = ({
  config = {
    selector: {
      body: {},
      handler: () => ({}),
      oneFile: false,
      reminder: false,
      description: false,
    },
    mode: 'full', // Full, lite
  },
}) => {
  const [, l] = useI18n();
  const [modal, setModal] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [fileType, setFileType] = useState('');
  const [folderModal, setFolderModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [formRef] = Form.useForm();
  const [auth] = useAuth();
  const [folderName, setFolderName] = useState('');
  const [order, setOrder] = useState(1);
  // Contiene el id del usuario
  const [externalUserId, setExternalUserId] = useState(undefined);
  // Contiene el nombre del usuario
  const [externalUserName, setExternalUserName] = useState(undefined);
  const [key, setKey] = useState('public');
  const [fileManager, setFileManager] = useState(
    immutable({
      parentNode: '/',
      currentPath: '',
      backPath: [],
    })
  );
  const [folderLoading, setFolderLoading] = useState(false);
  const [files, loading, , update, find] = useFetchUserFilesPath(
    auth.role.rol,
    auth.user._id,
    fileManager.currentPath,
    key
  );
  const { body, handler, oneFile, description } = config.selector;

  const props = {
    fileList,
    name: 'files',
    multiple: true,
    action: `${getServer()}/multimedia`,
    data: {
      is_public: key === 'public',
      custom_path: fileManager.currentPath.replace(`nordos/${key}/`, ''),
    },
    accept: process.env.REACT_APP_MUL_ALLOW_EXTENSIONS || '*',
    headers: {
      Authorization: `bearer ${auth.token}`,
    },
    onChange(info) {
      const { status } = info.file;
      setFileList(info.fileList);
      if (status === 'done') {
        message.success(l('SUCCESS_FILE_UPLOAD'));
        if (config.mode === 'lite') {
          let newFiles = [];
          //Soporte para multiples archivos (cada fileList tiene su arreglo de response con los datos)
          for (let fileRs of info.fileList) {
            if (Array.isArray(fileRs.response)) {
              for (let res of fileRs.response) {
                newFiles.push(res);
              }
            }
          }
          handler({
            ...body,
            files: [...body.files, ...newFiles],
          });
        }
        if (
          info.fileList.filter(item => item.status === 'done').length ===
          info.fileList.length
        ) {
          setFileList([]);
          update();
        }
        setModal(false);
      } else if (status === 'error') {
        message.error(l('ERROR_UPLOAD_FILE'));
      }
    },
  };

  const _confirmDelete = (file_id, type = 'file', event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    Modal.confirm({
      title:
        type === 'file' ? l('DELETE_FILE_TITLE') : l('DELETE_FOLDER_TITLE'),
      content:
        type === 'file' ? l('DELETE_FILE_CONTENT') : l('DELETE_FOLDER_CONTENT'),
      okText: l('YES'),
      cancelText: l('NO'),
      onOk: async () => {
        if (type === 'file') {
          await deleteFile(file_id).then(response => {
            if (response.ok) {
              message.success(l('FILE_DELETED'));
              update();
            } else {
              message.error(l('DELETE_FILE_ERROR'));
            }
          });
        } else {
          await deleteFolder(file_id).then(response => {
            if (response.ok) {
              message.success(l('FOLDER_DELETED'));
              update();
            } else {
              message.error(l('DELETE_FOLDER_ERROR'));
            }
          });
        }
      },
    });
  };

  const _filterPathFiles = () => {
    let { parentNode, currentPath } = fileManager,
      aFiles = [],
      aFolders = [],
      newArray = [];

    for (let path of files.CommonPrefixes) {
      let preKey = path.Prefix.replace(parentNode, '').split('/');
      let fakeKey = path?.FakePath?.replace(parentNode, '').split('/');
      aFolders.push({
        ...path,
        type: 'Folder',
        RealName: fakeKey
          ? fakeKey[fakeKey.length - 2]
          : preKey[preKey.length - 2] || '1',
      });
    }

    for (let path of files.Contents) {
      if (path.RealName) {
        aFiles.push({
          ...path,
          type: 'File',
        });
      }
    }
    // Order with Natural Sort
    aFiles = naturalSort(aFiles, order);
    aFolders = naturalSort(aFolders, order);

    if (currentPath !== '') {
      newArray.push({ type: 'Back' });
    }

    newArray = newArray.concat(aFolders);
    newArray = newArray.concat(aFiles);

    return loading ? [] : newArray;
  };

  const _handleSearchFile = name => {
    clearTimeout(timeout);
    if (name.trim() !== '') {
      timeout = setTimeout(() => {
        find(name, fileManager.parentNode);
      }, 1000);
    } else {
      timeout = setTimeout(() => {
        update();
      }, 1000);
    }
  };

  const _createFolder = async values => {
    setFolderLoading(true);
    let response = await createFolder(
      _parseRelativePath(fileManager, values, key),
      {
        is_public: key === 'public',
      }
    );

    if (response?.ok) {
      update();
      formRef.resetFields();
      setFolderModal(false);
    }
    setFolderLoading(false);
  };

  const _updateFile = async ({ name }) => {
    setModalLoading(true);
    let response = await updateFile(fileId, { name: `${name}.${fileType}` });
    if (response.ok) {
      update();
      formRef.resetFields();
      setRenameModal(false);
      setFileId(null);
      setFileType('');
    }
    setModalLoading(false);
  };

  const _handleClickEditName = item => {
    const core = item.RealName.split('.');
    const type = core[core.length - 1];
    let name = '';
    for (let i = 0; i < core.length - 1; i++) {
      name += core[i];
      if (i < core.length - 2) {
        name += '.';
      }
    }
    setFileId(item._id);
    setRenameModal(true);
    setFileType(type);
    formRef.setFieldsValue({
      name: name,
    });
  };

  useEffect(() => {
    let sCurrent = '';
    if (fileManager) {
      if (fileManager.currentPath) {
        if (fileManager.parentNode) {
          sCurrent = fileManager.currentPath.replace(`nordos/${key}/`, '');
        }
      }
    }

    if (externalUserId && externalUserName) {
      sCurrent = sCurrent.replace(externalUserId, externalUserName);
    }

    setBreadcrumb(sCurrent.split('/'));
  }, [fileManager, externalUserId, externalUserName, key, auth.user._id]);

  const _removeFileInList = id => {
    handler({
      ...body,
      files: body.files.filter(e => e._id !== id),
    });
  };

  const _setFileInList = file => {
    handler({
      ...body,
      files: [...body.files, file],
    });
  };

  const _renderFiles = () => {
    if (loading) {
      return <Skeleton active />;
    }

    return (
      <div className="file" style={{ height: 250, overflow: 'auto' }}>
        <List
          itemLayout="horizontal"
          dataSource={_filterPathFiles()}
          locale={{ emptyText: l('NO_FILES') }}
          loading={loading}
          pagination={{ defaultPageSize: 100 }}
          renderItem={item => {
            if (item.type === 'Folder') {
              return (
                <List.Item
                  style={{ cursor: 'pointer' }}
                  className="folder-element"
                  onClick={() =>
                    setFileManager(
                      fileManager.merge({
                        currentPath: item.Prefix,
                        backPath: [
                          ...fileManager.backPath,
                          fileManager.currentPath,
                        ],
                      })
                    )
                  }
                >
                  <List.Item.Meta
                    avatar={
                      <FileIcon
                        fileType="folder"
                        style={{ fontSize: 30 }}
                        twoToneColor="#f75757"
                      />
                    }
                    title={item.RealName}
                  />
                </List.Item>
              );
            }
            if (item.type === 'Back') {
              return (
                <List.Item
                  style={{ cursor: 'pointer' }}
                  className="back-element"
                  onClick={() =>
                    setFileManager(
                      fileManager.merge({
                        currentPath:
                          fileManager.backPath[fileManager.backPath.length - 1],
                        backPath: fileManager.backPath.slice(
                          0,
                          fileManager.backPath.length - 1
                        ),
                      })
                    )
                  }
                >
                  <List.Item.Meta
                    avatar={
                      <FileIcon
                        fileType="arrow-l"
                        style={{ color: '#f75757' }}
                        size={22}
                        strokeWidth={2}
                      />
                    }
                    title={l('RETURN')}
                  />
                </List.Item>
              );
            }
            return (
              <List.Item
                actions={[
                  <Checkbox
                    key="select"
                    disabled={
                      oneFile &&
                      body.files.length === 1 &&
                      body.files[0]._id !== item._id
                    }
                    checked={body.files.some(e => e._id === item._id)}
                    onChange={e => {
                      if (e.target.checked) {
                        _setFileInList({ name: item.RealName, _id: item._id });
                      } else {
                        _removeFileInList(item._id);
                      }
                    }}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <FileIcon
                      fileType={getFileType(item.RealName)}
                      style={{ fontSize: 30 }}
                      twoToneColor="#F75757"
                    />
                  }
                  title={item.RealName}
                />
              </List.Item>
            );
          }}
        />
      </div>
    );
  };

  const _renderBody = () => {
    if (config.mode === 'lite') {
      return (
        <div>
          <Tabs defaultActiveKey="1">
            <TabPane tab={l('FILES')} key="1">
              <div>
                <span
                  style={{
                    display: 'inline-block',
                    marginRight: 10,
                    marginBottom: 20,
                  }}
                >
                  {l('DIRECTORY')}:
                </span>
                {breadcrumb.length && (
                  <Breadcrumb
                    style={{
                      display: 'inline-block',
                    }}
                  >
                    <Breadcrumb.Item>
                      <HomeOutlined />
                    </Breadcrumb.Item>
                    {breadcrumb.map(item => (
                      <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                )}
                <Row justify="space-between" gutter={[10, 10]}>
                  <Col span={12}>
                    <Input
                      placeholder={l('FOLDER_NAME')}
                      value={folderName}
                      onChange={e => setFolderName(e.target.value)}
                    />
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Button
                      icon={
                        <FolderPlus
                          size={14}
                          strokeWidth={2}
                          className="anticon"
                        />
                      }
                      disabled={folderName === ''}
                      onClick={async () =>
                        await _createFolder({ name: folderName })
                      }
                    >
                      {l('CREATE_FOLDER')}
                    </Button>
                  </Col>
                </Row>
              </div>
              <div>{_renderFiles()}</div>
            </TabPane>
            <TabPane tab={l('UPLOAD_FILE')} key="2">
              <span
                style={{
                  display: 'inline-block',
                  marginRight: 10,
                  marginBottom: 20,
                }}
              >
                {l('DIRECTORY')}:
              </span>
              {breadcrumb.length && (
                <Breadcrumb
                  style={{
                    display: 'inline-block',
                  }}
                >
                  <Breadcrumb.Item>
                    <HomeOutlined />
                  </Breadcrumb.Item>
                  {breadcrumb.map(item => (
                    <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              )}
              <div style={{ minHeight: 250 }}>
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">{l('UPLOAD_MODAL_TEXT')}</p>
                  <p className="ant-upload-hint">{l('UPLOAD_MODAL_CONTENT')}</p>
                </Dragger>
              </div>
            </TabPane>
          </Tabs>
          {description && (
            <Input
              placeholder={oneFile ? l('NAME') : l('DESCRIPTION')}
              style={{ marginTop: 20 }}
              value={body.description}
              onChange={e => handler({ ...body, description: e.target.value })}
            />
          )}

          <List
            style={{ marginTop: 20 }}
            bordered
            pagination={{ defaultPageSize: 100 }}
            dataSource={body.files}
            locale={{ emptyText: l('NO_SELECTED_FILES') }}
            renderItem={item => (
              <List.Item key={item._id}>{item.name}</List.Item>
            )}
          />
        </div>
      );
    }

    return (
      <div className="file-wrapper">
        <Row justify="space-between">
          <Col>
            <Button
              type="primary"
              icon={<Upload size={14} strokeWidth={2} className="anticon" />}
              onClick={() => setModal(true)}
              style={{ marginRight: 8 }}
            >
              {l('UPLOAD_FILE')}
            </Button>
            <Button
              onClick={() => setFolderModal(true)}
              icon={
                <FolderPlus size={14} strokeWidth={2} className="anticon" />
              }
            >
              {l('CREATE_FOLDER')}
            </Button>
          </Col>
          <Col>
            <Row gutter={[10, 0]}>
              <Col>
                <Radio.Group
                  options={[
                    {
                      label: <SortAscendingOutlined />,
                      value: 1,
                    },
                    {
                      label: <SortDescendingOutlined />,
                      value: -1,
                    },
                  ]}
                  onChange={e => setOrder(e.target.value)}
                  value={order}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Col>
              <Col>
                <Input
                  placeholder={l('SEARCH_FILE')}
                  onChange={e => _handleSearchFile(e.target.value)}
                  allowClear
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Col>
            <span
              style={{
                display: 'inline-block',
                marginRight: 10,
              }}
            >
              {l('DIRECTORY')}:
            </span>
            {breadcrumb.length && (
              <Breadcrumb
                style={{
                  display: 'inline-block',
                }}
              >
                <Breadcrumb.Item>
                  <HomeOutlined />
                </Breadcrumb.Item>
                {breadcrumb.map(item => (
                  <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
                ))}
              </Breadcrumb>
            )}
          </Col>
        </Row>
        <div className="file">
          <List
            itemLayout="horizontal"
            dataSource={_filterPathFiles()}
            locale={{ emptyText: l('NO_FILES') }}
            loading={loading}
            pagination={{ defaultPageSize: 100 }}
            renderItem={item => {
              if (item.type === 'Folder') {
                return (
                  <List.Item
                    style={{ cursor: 'pointer' }}
                    className="folder-element"
                    actions={[
                      <Button
                        danger
                        key="1"
                        type="primary"
                        onClick={event =>
                          _confirmDelete(item.Prefix, 'folder', event)
                        }
                        icon={
                          <Trash
                            size={14}
                            strokeWidth={2}
                            className="anticon"
                          />
                        }
                      />,
                    ]}
                    onClick={() => {
                      setFileManager(
                        fileManager.merge({
                          currentPath: item.Prefix,
                          backPath: [
                            ...fileManager.backPath,
                            fileManager.currentPath,
                          ],
                        })
                      );
                      if (fileManager.currentPath.length === 0) {
                        setExternalUserId(
                          item?.Prefix?.replace('nordos/users/', '')
                        );
                        setExternalUserName(
                          item?.FakePath?.replace('nordos/users/', '')
                        );
                      }
                    }}
                  >
                    <List.Item.Meta
                      avatar={
                        <FileIcon
                          fileType="folder"
                          style={{ fontSize: 30 }}
                          twoToneColor="#f75757"
                        />
                      }
                      title={item.RealName}
                    />
                  </List.Item>
                );
              }
              if (item.type === 'Back') {
                return (
                  <List.Item
                    style={{ cursor: 'pointer' }}
                    className="back-element"
                    onClick={() => {
                      setFileManager(
                        fileManager.merge({
                          currentPath:
                            fileManager.backPath[
                              fileManager.backPath.length - 1
                            ],
                          backPath: fileManager.backPath.slice(
                            0,
                            fileManager.backPath.length - 1
                          ),
                        })
                      );
                    }}
                  >
                    <List.Item.Meta
                      avatar={
                        <FileIcon
                          fileType="arrow-l"
                          style={{ color: '#f75757' }}
                          size={22}
                          strokeWidth={2}
                        />
                      }
                      title={l('RETURN')}
                    />
                  </List.Item>
                );
              }

              return (
                <List.Item
                  actions={[
                    <PreviewButton
                      path={item.file_path}
                      fileName={item.RealName}
                      fileType={item.FileType}
                      key="1"
                    />,
                    <LoadingButton
                      path={item.file_path}
                      fileName={item.RealName}
                      key="2"
                    />,
                    <Button
                      type="dashed"
                      onClick={() => {
                        _handleClickEditName(item);
                      }}
                      icon={
                        <Edit size={14} strokeWidth={2} className="anticon" />
                      }
                      key="3"
                    />,
                    <Button
                      danger
                      type="primary"
                      onClick={() => _confirmDelete(item._id)}
                      icon={
                        <Trash size={14} strokeWidth={2} className="anticon" />
                      }
                      key="4"
                    />,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <FileIcon
                        fileType={_getFileType(item.RealName)}
                        style={{ fontSize: 30 }}
                        twoToneColor="#f75757"
                      />
                    }
                    title={item.RealName}
                    description={
                      <>
                        <div>{`${l('MODIFICATION_DATE')}: ${dayjs(
                          item.LastModified
                        ).format('DD/MM/YYYY hh:ss a')}`}</div>
                        <div>{`${item.Size === -1 ? 'Ruta: ' : 'Tamaño: '} ${
                          item.Size === -1
                            ? item?.FakePath || '/'
                            : fileSize(item.Size)
                        }`}</div>
                      </>
                    }
                  />
                </List.Item>
              );
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        title={l('UPLOAD_FILE')}
        visible={modal}
        onCancel={() => setModal(false)}
        footer={[]}
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{l('UPLOAD_MODAL_TEXT')}</p>
          <p className="ant-upload-hint">{l('UPLOAD_MODAL_CONTENT')}</p>
        </Dragger>
      </Modal>
      <Modal
        title={l('NEW_FOLDER')}
        visible={folderModal}
        onOk={() => formRef.submit()}
        onCancel={() => {
          setFolderModal(false);
          formRef.resetFields();
        }}
        okText={l('CREATE')}
        cancelText={l('CANCEL')}
        okButtonProps={{ loading: folderLoading }}
      >
        <FolderForm
          formRef={formRef}
          onSubmit={_createFolder}
          label={l('FOLDER_NAME')}
        />
      </Modal>
      <Modal
        title={l('RENAME_FILE')}
        visible={renameModal}
        onOk={() => {
          setFolderModal(false);
          formRef.submit();
        }}
        onCancel={() => {
          setRenameModal(false);
          setFileId(null);
          formRef.resetFields();
        }}
        okText={l('RENAME')}
        okButtonProps={{ loading: modalLoading }}
        cancelText={l('CANCEL')}
      >
        <FolderForm
          formRef={formRef}
          onSubmit={_updateFile}
          label={l('FILE_NAME')}
        />
      </Modal>
      <Tabs
        defaultActiveKey="public"
        activeKey={key}
        onChange={e => {
          setKey(e);
          setExternalUserId(undefined);
          setExternalUserName(undefined);
          setFileManager(
            immutable({
              parentNode: '/',
              currentPath: '',
              backPath: [],
            })
          );
        }}
      >
        <TabPane tab={l('PUBLIC')} key="public"></TabPane>
        <TabPane tab={l('USERS')} key="users"></TabPane>
      </Tabs>
      {_renderBody()}
    </div>
  );
};

FilesWrapper.propTypes = {
  config: PropTypes.any,
  reminder: PropTypes.any,
  _setReminder: PropTypes.any,
};
export default FilesWrapper;
