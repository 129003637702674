import { Divider, Layout } from 'antd';
import React, { useState } from 'react';
// Components
import Logout from '../../Components/Layout/Logout';
import MenuDashboard from '../../Components/Layout/Menu';
import MenuMobile from '../../Components/Layout/MenuMobile';
import Logo from '../../Components/Layout/Logo';
import { useDrawer } from '../../Hooks/App.hook';
import { useMobile } from '../../Hooks/Responsive.hook';

const { Sider } = Layout;

const Sidebar = () => {
  const [visible, handler] = useDrawer();
  const [collapsed, setCollapsed] = useState(true);
  const [isMobile] = useMobile();

  return (
    <>
      {isMobile ? (
        <MenuMobile drawerOptions={{ visible, handler }} />
      ) : (
        <Sider
          className="sidebar-app"
          collapsed={collapsed}
          collapsible
          defaultCollapsed
          onCollapse={status => setCollapsed(status)}
          width={250}
        >
          <Logo collapsed={collapsed}></Logo>
          <Divider style={{ borderTopColor: 'rgba(255, 255, 255, 0.65)' }} />
          <MenuDashboard />
          <Divider style={{ borderTopColor: 'rgba(255, 255, 255, 0.65)' }} />
          <Logout />
        </Sider>
      )}
    </>
  );
};

export default Sidebar;
