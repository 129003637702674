import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useI18n } from './../../Hooks/i18n.hook';

const HivesForm = ({ formRef, onSubmit, edit = true, ...props }) => {
  const [, l] = useI18n();
  const [editSpeed, setEditSpeed] = useState(
    formRef.getFieldValue('speed_alert')
  );
  return (
    <Form form={formRef} onFinish={onSubmit} layout="vertical" {...props}>
      <Form.Item
        label={l('NAME')}
        name="name"
        rules={[{ required: true, message: l('_fieldRequired') }]}
      >
        <Input disabled={!edit} />
      </Form.Item>
      <Form.Item
        label={l('DESCRIPTION')}
        name="description"
        rules={[{ required: true, message: l('_fieldRequired') }]}
      >
        <Input disabled={!edit} />
      </Form.Item>
      <Form.Item label={l('TYPE')} name="type">
        <Select defaultValue="work" disabled={!edit}>
          <Select.Option value="work">{l('HIVES_OPTION_WORK')}</Select.Option>
          <Select.Option value="private">
            {l('HIVES_OPTION_PRIVATE')}
          </Select.Option>
          <Select.Option value="family">
            {l('HIVES_OPTION_FAMILY')}
          </Select.Option>
          <Select.Option value="friends">
            {l('HIVES_OPTION_FRIENDS')}
          </Select.Option>
          <Select.Option value="classmates">
            {l('HIVES_OPTION_CLASSMATES')}
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label={l('STATUS')} name="status">
        <Select defaultValue={1} disabled={!edit}>
          <Select.Option value={1}>{l('ACTIVE')}</Select.Option>
          <Select.Option value={0}>{l('INACTIVE')}</Select.Option>
        </Select>
      </Form.Item>
      <Row>
        <Col span={6}>
          <Form.Item label={l('MAX_SPEED')} name="max_speed">
            <Input
              disabled={!editSpeed || !edit}
              placeholder={l('KM_PER_HOUR')}
              type="number"
            />
          </Form.Item>
        </Col>
        <Col span={7} offset={1}>
          <Form.Item
            label={l('SPEED_ALERT')}
            name="speed_alert"
            valuePropName="checked"
          >
            <Checkbox
              onChange={e => {
                setEditSpeed(!editSpeed);
                formRef.setFieldsValue({ speed_alert: e.target.checked });
              }}
              disabled={!edit}
            />
          </Form.Item>
        </Col>
        <Col span={6} offset={3}>
          <Form.Item
            label={l('LOGOUT_ALERT')}
            name="logout_alert"
            valuePropName="checked"
          >
            <Checkbox
              onChange={e => {
                formRef.setFieldsValue({ logout_alert: e.target.checked });
              }}
              disabled={!edit}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
HivesForm.propTypes = {
  formRef: PropTypes.any,
  onSubmit: PropTypes.func,
  edit: PropTypes.bool,
};
export default HivesForm;
