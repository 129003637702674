import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faPlus,
  faPencilAlt,
  faTrash,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Row, Popover, Modal, Input, Avatar } from 'antd';
import DynamicTable from '../../Components/Data/Table';
import DefaultAvatar from './../../Assets/img/avatar-default.png';
//Hooks
import { useColumn, UsersColumns } from '../../Hooks/Columns.hook';
import { useFetchUsers } from '../../Hooks/Users.hook';
import { useAuth } from '../../Hooks/Auth.hook';
import UserModal from '../../Components/Data/UserModal';
import { useI18n } from '../../Hooks/i18n.hook';
import {
  updateUser,
  getUserById,
  findUserByNameOrEmail,
} from '../../Services/API';
import Image from '../../Components/Antd/Image';

const { Search } = Input;

const UsersScreen = () => {
  const [, l] = useI18n();
  const [auth] = useAuth();
  const [, getColumn] = useColumn(UsersColumns);
  const [users, loading, , change, update, seLoading] = useFetchUsers();
  const [modal, setModal] = useState(false);
  const [popoverId, setPopoverId] = useState(undefined);
  const [selectedUser, setSelectedUser] = useState({});
  const [showPopover, setShowPopover] = useState(false);
  const [search, setSearch] = useState('');
  const [searchPressed, setSearchPressed] = useState(true);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const columns = [
    {
      ...getColumn('photo'),
      width: 100,
      render(e) {
        return e ? (
          <Avatar size={38} src={<Image src={e} />} />
        ) : (
          <Avatar size={38} src={DefaultAvatar} />
        );
      },
    },
    {
      ...getColumn('full_name'),
      width: 200,
    },
    {
      ...getColumn('email'),
      width: 300,
    },
    {
      ...getColumn('status'),
      width: 200,
      render(e) {
        if (e) {
          return l('ACTIVE');
        }
        return l('INACTIVE');
      },
    },
    {
      title: '',
      dataIndex: '_id',
      width: 200,
      render(data, row) {
        return (
          <Popover
            trigger="click"
            visible={popoverId === data && showPopover}
            onVisibleChange={visible => {
              setShowPopover(visible);
            }}
            content={
              <div className="popover-common">
                <ul>
                  <li
                    onClick={() => {
                      setPopoverId(undefined);
                      getSpecificUser(data);
                      setModal(true);
                    }}
                  >
                    {l('EDIT')}
                    <FontAwesomeIcon icon={faPencilAlt} className="anticon" />
                  </li>
                  <li
                    className={row.status ? 'danger' : 'success'}
                    onClick={() => {
                      Modal.confirm({
                        title: row.status
                          ? l('DISABLE_USER')
                          : l('ENABLE_USER'),
                        onOk: async () => {
                          let response = await updateUser(data, {
                            status: row.status ? 0 : 1,
                          });
                          if (response.ok) {
                            update();
                          }
                        },
                      });
                    }}
                  >
                    {row.status ? (
                      <>
                        {l('DISABLE')}
                        <FontAwesomeIcon icon={faTrash} className="anticon" />
                      </>
                    ) : (
                      <>
                        {l('ENABLE')}
                        <FontAwesomeIcon icon={faCheck} className="anticon" />
                      </>
                    )}
                  </li>
                </ul>
              </div>
            }
            placement="bottom"
          >
            <div style={{ width: 30 }}>
              <FontAwesomeIcon
                onClick={() => {
                  setPopoverId(data);
                }}
                icon={faEllipsisV}
                className="anticon trigger"
              />
            </div>
          </Popover>
        );
      },
    },
  ];

  const getSpecificUser = React.useCallback(async _id => {
    const oResponse = await getUserById(_id);
    if (oResponse.ok) {
      setSelectedUser(oResponse.data);
    }
  }, []);

  const onSearch = React.useCallback(async () => {
    if (search) {
      seLoading(true);
      setSearchPressed(true);
      const oResponse = await findUserByNameOrEmail(search);
      if (oResponse.ok) {
        setSearchedUsers(oResponse.data.data);
        seLoading(false);
      }
    }
  }, [search]);

  useEffect(() => {
    if (!search) {
      setSearchPressed(false);
    }
  }, [search]);

  return (
    <div className="wrapper">
      <div className="content">
        <UserModal
          visible={modal}
          onCancel={() => {
            setModal(false);
            setSelectedUser({});
          }}
          okText={selectedUser?._id ? l('UPDATE') : l('ADD')}
          user={selectedUser}
          updater={() => {
            update();
            setModal(false);
            setSelectedUser({});
          }}
        />
        <Row className="catalog-layout">
          <Col flex="none">
            <Row justify="space-between" className="title">
              {auth.role.rol !== 'bees' ? (
                <>
                  <Col>
                    <h3>
                      {users.total} {l('USERS')}
                    </h3>
                  </Col>
                  <Row>
                    <Col>
                      <Search
                        placeholder={l('GROUP_NAME_PLACEHOLDER')}
                        size="large"
                        onChange={e => setSearch(e.target.value)}
                        onSearch={onSearch}
                        style={{ width: 300 }}
                      />
                    </Col>
                    <Col>
                      <Button
                        size="large"
                        icon={
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="anticon pointer"
                          />
                        }
                        onClick={() => setModal(true)}
                      >
                        {l('ADD_NEW_USER')}
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <Col>
                  <h3>{l('USER')}</h3>
                </Col>
              )}
            </Row>
          </Col>
          <Col flex="auto">
            <div className="dynamic-table">
              <DynamicTable
                columns={columns}
                dataSource={
                  search && searchPressed ? searchedUsers : users.data
                }
                loading={loading}
                rowKey={e => e._id}
                pagination={{
                  total:
                    search && searchPressed
                      ? searchedUsers.length
                      : users.total,
                  current: users.params.skip / 10 + 1,
                  onChange: e => change(users.params.queries, (e - 1) * 10),
                  pageSizeOptions: [10],
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UsersScreen;
