import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faPlus,
  faPencilAlt,
  faTrash,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Row, Popover, Modal } from 'antd';
import DynamicTable from '../../Components/Data/Table';
import CategoryForm from '../../Components/Form/Category.form';
import CreationModal from '../../Components/Data/CreationModal';

//Hooks
import { ColorColumns, useColumn } from '../../Hooks/Columns.hook';
import { useFetchClasses } from '../../Hooks/Classes.hook';
import { useI18n } from '../../Hooks/i18n.hook';
//Services
import { updateClass, createClass } from '../../Services/API';

const ClassesScreen = () => {
  const [, l] = useI18n();
  const [, getColumn] = useColumn(ColorColumns);
  const [users, loading, , change, update] = useFetchClasses();
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const columns = [
    getColumn('name'),
    {
      ...getColumn('status'),
      render(e) {
        if (e) {
          return l('ACTIVE');
        }
        return l('INACTIVE');
      },
    },
    {
      title: '',
      dataIndex: '_id',
      render(data, row) {
        const [triggerVisible, setTriggerVisible] = useState(false);
        return (
          <Popover
            trigger="click"
            onVisibleChange={e => {
              setTriggerVisible(e);
            }}
            visible={triggerVisible}
            content={
              <div className="popover-common">
                <ul>
                  <li
                    onClick={() => {
                      setSelectedItem(row);
                      setModal(true);
                      setTriggerVisible(false);
                    }}
                  >
                    {l('EDIT')}
                    <FontAwesomeIcon icon={faPencilAlt} className="anticon" />
                  </li>
                  <li
                    className={row.status ? 'danger' : 'success'}
                    onClick={() => {
                      Modal.confirm({
                        title: row.status
                          ? l('DISABLE_CLASS')
                          : l('ENABLE_CLASS'),
                        onOk: async () => {
                          let response = await updateClass(data, {
                            status: row.status ? 0 : 1,
                          });
                          if (response.ok) {
                            update();
                          }
                        },
                      });
                    }}
                  >
                    {row.status ? (
                      <>
                        {l('DISABLE')}
                        <FontAwesomeIcon icon={faTrash} className="anticon" />
                      </>
                    ) : (
                      <>
                        {l('ENABLE')}
                        <FontAwesomeIcon icon={faCheck} className="anticon" />
                      </>
                    )}
                  </li>
                </ul>
              </div>
            }
            placement="bottom"
          >
            <div style={{ width: 30 }}>
              <FontAwesomeIcon icon={faEllipsisV} className="anticon trigger" />
            </div>
          </Popover>
        );
      },
    },
  ];

  return (
    <div className="wrapper">
      <div className="users-screen content">
        <CreationModal
          visible={modal}
          apiFn={{
            createRecord: createClass,
            updateRecord: updateClass,
          }}
          recordName={l('CLASS')}
          RecordForm={CategoryForm}
          onCancel={() => {
            setModal(false);
            setSelectedItem({});
          }}
          okText={selectedItem?._id ? l('UPDATE') : l('ADD')}
          item={selectedItem}
          updater={() => {
            update();
            setModal(false);
            setSelectedItem({});
          }}
        />
        <Row justify="space-between" className="title">
          <Col>
            <h3>
              {users.total} {l('CLASSES')}
            </h3>
          </Col>
          <Col>
            <Button
              onClick={() => setModal(true)}
              icon={<FontAwesomeIcon icon={faPlus} className="anticon" />}
            >
              {l('ADD_NEW_CLASS')}
            </Button>
          </Col>
        </Row>
        <div className="dynamic-table">
          <DynamicTable
            columns={columns}
            dataSource={users.data}
            loading={loading}
            rowKey={e => e._id}
            pagination={{
              total: users.total,
              current: users.params.skip / 10 + 1,
              onChange: e => change(users.params.queries, (e - 1) * 10),
              pageSizeOptions: [10],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ClassesScreen;
