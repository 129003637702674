import { Tabs } from 'antd';
import React from 'react';
import GeneralView from '../../Components/Settings/GeneralView';
import TagView from '../../Components/Settings/TagsView';

const SettingsScreen = () => {
  return (
    <div className="wrapper">
      <div className="settings-screen content">
        <Tabs defaultActiveKey="general">
          <Tabs.TabPane key="general" tab="General">
            <GeneralView />
          </Tabs.TabPane>
          <Tabs.TabPane key="tags" tab="Tags">
            <TagView />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default SettingsScreen;
