import React, { useState } from 'react';
import { Spin, Image as Img } from 'antd';
import { useI18n } from '../../Hooks/i18n.hook';
import PropTypes from 'prop-types';
import { getImageUrl } from '../../Services/API';

const Image = props => {
  const [loaded, setLoaded] = useState(false);
  const [, label] = useI18n();
  const [sImage, setImage] = React.useState('');

  const urlImage = React.useCallback(async () => {
    if (props.src) {
      const sResult = await getImageUrl(props.src);
      setImage(sResult);
    }
  }, [props.src, setImage]);

  React.useEffect(() => {
    urlImage();
  }, [urlImage, props.src]);

  return (
    <>
      <Img
        {...props}
        src={sImage}
        onLoad={() => setLoaded(true)}
        onError={e => console.log(e)}
        style={{ display: loaded ? 'block' : 'none' }}
        alt="Item"
      />
      <div
        className="loading-image"
        style={{
          display: !loaded ? 'block' : 'none',
        }}
      >
        <Spin>
          <div
            className="loading-content"
            style={{ height: '100%', width: '100%' }}
          >
            {label('LOADING')}
          </div>
        </Spin>
      </div>
    </>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Image;
