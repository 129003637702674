import { useEffect, useState } from 'react';
import { getPath, findFiles } from '../Services/API';

const sortContents = oResponse => {
  if (oResponse) {
    if (oResponse.Contents) {
      oResponse.Contents.sort((a, b) => {
        if (a.RealName && b.RealName) {
          let textA = a.RealName.toUpperCase(),
            textB = b.RealName.toUpperCase();

          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }

        return 0;
      });
    }

    if (oResponse.CommonPrefixes) {
      oResponse.CommonPrefixes.sort((a, b) => {
        let textA = a.Prefix.toUpperCase(),
          textB = b.Prefix.toUpperCase();

        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
  }

  return oResponse;
};

export const useFetchUserFilesPath = (role, user_id, path, mode = 'public') => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({ Contents: [], CommonPrefixes: [] });

  useEffect(() => {
    setLoading(true);
    getPath({
      is_public: mode === 'public',
      custom_path: !path ? '' : path.replace(`nordos/${mode}/`, ''),
    }).then(response => {
      if (response.ok) {
        setData(response.data);
      } else {
        setError(response.data);
      }
      setLoading(false);
    });
  }, [setData, setError, setLoading, path, role, mode, user_id]);

  async function update() {
    setLoading(true);
    getPath({
      is_public: mode === 'public',
      custom_path: !path ? '' : path.replace(`nordos/${mode}/`, ''),
    }).then(response => {
      if (response.ok) {
        setData(response.data);
      } else {
        setError(response.data);
      }
      setLoading(false);
    });
  }

  async function find(name) {
    setLoading(true);
    if (name && name !== '') {
      let response = await findFiles({
        name,
        path: !path
          ? mode === 'public'
            ? 'nordos/public/'
            : 'nordos/users/'
          : path,
      });

      if (response.ok) {
        setData(sortContents(response.data));
      } else {
        setError(response.data);
      }
      setLoading(false);
    } else {
      update();
    }
  }
  return [data, loading, error, update, find];
};
