import React from 'react';
import { Form, Select, Input, Row, Col } from 'antd';
import PropTypes from 'prop-types';
//Hooks
import { useI18n } from '../../Hooks/i18n.hook';
import { useFetchRoles } from '../../Hooks/Roles.hook';
import { useAuth } from '../../Hooks/Auth.hook';

const UserForm = ({ onSubmit, address, formRef, edit = '', screen = '' }) => {
  const [, l] = useI18n();
  const [auth] = useAuth();
  const [data, loading] = useFetchRoles();
  const ruleDisabledInDetails =
    screen === 'detail' && auth.user._id !== edit && auth.role.rol === 'bees';

  return (
    <Form name="User" onFinish={onSubmit} layout="vertical" form={formRef}>
      <Row gutter={[10, 10]}>
        <Col span={12}>
          <Form.Item
            label={l('FULL_NAME')}
            name="full_name"
            rules={[{ required: true, message: l('_fieldRequired') }]}
          >
            <Input disabled={ruleDisabledInDetails} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={l('EMAIL')}
            name="email"
            rules={[{ required: true, message: l('_fieldRequired') }]}
          >
            <Input type="email" disabled={ruleDisabledInDetails} />
          </Form.Item>
        </Col>
      </Row>
      {!ruleDisabledInDetails && (
        <>
          <Row gutter={[10, 10]}>
            <Col span={9}>
              <Form.Item
                name="password"
                label={l('PASSWORD')}
                rules={[
                  {
                    required: !edit,
                    message: l('_inputPasswordAlert'),
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                name="rpassword"
                label={l('CONFIRM_PASSWORD')}
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: !edit,
                    message: l('_confirmPasswordDialog'),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(l('_passwordMatch'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={l('STATUS')} name="status">
                <Select>
                  <Select.Option value={1}>{l('ACTIVE')}</Select.Option>
                  <Select.Option value={0}>{l('INACTIVE')}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {auth.role.rol !== 'bees' && (
            <Row gutter={[10, 10]}>
              <Col span={16}>
                <Form.Item label={l('LOCATION')} name="location">
                  <Input
                    disabled={true}
                    value={address}
                    style={{
                      color: 'black',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={l('ROLE')}
                  name="rol_id"
                  rules={[{ required: true, message: l('_fieldRequired') }]}
                >
                  <Select loading={loading}>
                    {data.data.map(role => (
                      <Select.Option value={role._id} key={role._id}>
                        {role.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      )}
    </Form>
  );
};

UserForm.propTypes = {
  onSubmit: PropTypes.func,
  edit: PropTypes.string,
  formRef: PropTypes.any,
  address: PropTypes.string,
  screen: PropTypes.string,
};

export default UserForm;
