const Styles = {
  mt10: {
    marginTop: 10,
  },
  mt30: {
    marginTop: 30,
  },
  mt50: {
    marginTop: 50,
  },
};

export default Styles;
