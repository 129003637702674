/* eslint-disable indent */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  MarkerClusterer,
  OverlayView,
} from '@react-google-maps/api';
import {
  Button,
  Card,
  Col,
  Input,
  List,
  Modal,
  notification,
  Radio,
  Row,
  Select,
} from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import UserModal from '../../Components/Data/UserModal';
import GroupDetailModal from '../../Components/Data/CreationModal';
import HiveForm from '../../Components/Form/Hives.form';
import SwarmsForm from '../../Components/Form/Swarm.form';

import { DEFAULT_LAT, DEFAULT_LNG } from '../../Constants/constants';
import Styles from '../../Constants/Styles';
import { useI18n } from '../../Hooks/i18n.hook';
import { findBeeOrGroup, noRepeatedBees } from '../../Utils/helpers';
import MAP_SETTINGS from './../../Constants/MapConstants';
import { updateSwarm, updateHive } from '../../Services/API';
import {
  createAlert,
  getAlertCategories,
  getBees,
  getHives,
  getMembers,
  getUserById,
  getSwarms,
} from './../../Services/API';
import { getGoogleApiKey } from './../../Utils/url';

const { TextArea, Search } = Input;

const styles = {
  rowContainerStyle: {
    height: '100%',
    width: '100%',
  },
};

const { DEFAULT_CENTER, DEFAULT_ZOOM, MAP_CONTAINER_STYLE } = MAP_SETTINGS;

const browserGeolocationOpts = {
  enableHighAccuracy: true,
  maximumAge: 0,
  timeout: 5000,
};

const checkboxInitOptions = [
  {
    checked: false,
    label: 'todos',
    target_id: '',
    type: 'all',
    value: 'all',
  },
];

const HomeScreen = () => {
  const [, l] = useI18n();
  const [bees, setBees] = useState([]);
  const [members, setMembers] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const [homeLoading, setHomeLoading] = useState(true);
  const [map, setMap] = useState(null);
  const [, setRenderListSearch] = useState(false);
  const [groupModal, setGroupModal] = useState({
    visible: false,
    data: {},
    type: null,
  });
  const [nonRepeatedBees, setNonRepeatedBees] = useState([]);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertCategories, setAlertCategories] = useState([]);
  const [nonRepeatedMembers, setNonRepeatedMembers] = useState([]);
  const [searchBeeInAlert, setSearchBeeInAlert] = useState('');
  const [searchBeeOnList, setSearchBeeOnList] = useState('');
  const [hivesData, setHivesData] = useState([]);
  const [swarmsData, setSwarmsData] = useState([]);
  const [centerMap, setCenterMap] = useState(DEFAULT_CENTER);
  const [groupsOrBees, setGroupsOrBees] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [beesOnList, setBeesOnList] = useState([]);
  const [listFiltered, setListFiltered] = useState(false);
  const [hideAllMarkers, setHideAllMarkers] = useState(false);
  const [updateBeesOrMembers, setUpdateBeesOrMembers] = useState({
    type: '',
    _id: '',
  });
  let markersInstances = useRef({});
  let markerClusterRef = useRef();
  const [userModalData, setUserModalData] = useState({
    visible: false,
    user: {},
  });
  const [checkboxAlertOpts, setCheckboxAlertOpts] = useState(
    checkboxInitOptions
  );
  const [hideMarkersIdx, setHideMarkersIdx] = useState([]);
  const mapRef = useRef();

  const [alertBodyObj, setAlertBodyObj] = useState({
    alertDescription: '',
    alertType: '',
  });
  const [calloutData, setCalloutData] = useState({
    updated_at: undefined,
    user: undefined,
  });
  const [userPosition, setUserPosition] = useState({
    lat: undefined,
    lng: undefined,
  });
  const [overlayOptions, setOverlayOptions] = useState({
    position: {
      lat: 6.275809475471447,
      lng: -75.59617025089038,
    },
    show: false,
  });
  const get_alert_categories = useCallback(async () => {
    const oResponse = await getAlertCategories();
    if (oResponse.ok) {
      setAlertCategories(oResponse.data.data);
    }
  }, []);
  const { auth } = useSelector(state => ({ auth: state.auth }));

  useEffect(() => {
    get_alert_categories();
  }, [get_alert_categories]);

  const onLoad = useCallback(map2 => setMap(map2), []);

  const getAllBees = async () => {
    let tmpBees = [],
      skip = 0,
      limit = 50,
      current = 0,
      total = 0;
    do {
      const beeResponse = await getBees([], skip, limit);
      if (beeResponse?.data?.data) {
        tmpBees = [...tmpBees, ...beeResponse.data.data];
      }
      current = tmpBees.length;
      skip = current;
      total = beeResponse.data?.total || 0;
    } while (current < total);
    return tmpBees;
  };
  const getAllMembers = async () => {
    let tmpMembers = [],
      skip = 0,
      limit = 50,
      current = 0,
      total = 0;
    do {
      const beeResponse = await getMembers([], skip, limit);
      if (beeResponse?.data?.data) {
        tmpMembers = [...tmpMembers, ...beeResponse.data.data];
      }
      current = tmpMembers.length;
      skip = current;
      total = beeResponse.data?.total || 0;
    } while (current < total);
    return tmpMembers;
  };
  const getAllHives = async () => {
    let tmpList = [],
      skip = 0,
      limit = 50,
      current = 0,
      total = 0;
    do {
      const beeResponse = await getHives([], skip, limit);
      if (beeResponse?.data?.data) {
        tmpList = [...tmpList, ...beeResponse.data.data];
      }
      current = tmpList.length;
      skip = current;
      total = beeResponse.data.total || 0;
    } while (current < total);
    return tmpList;
  };
  const getAllSwarms = async () => {
    let tmpList = [],
      skip = 0,
      limit = 50,
      current = 0,
      total = 0;
    do {
      const beeResponse = await getSwarms([], skip, limit);
      if (beeResponse?.data?.data) {
        tmpList = [...tmpList, ...beeResponse.data.data];
      }
      current = tmpList.length;
      skip = current;
      total = beeResponse.data?.total || 0;
    } while (current < total);
    return tmpList;
  };

  const returnGroupedBees = React.useCallback(() => {
    return noRepeatedBees(bees.concat(members));
  }, [bees, members]);

  const findBeesOrGroups = useCallback(
    async (search, listSearch) => {
      let userGroups = [];
      let groupedBees = [];
      if (hivesData?.length || swarmsData?.length) {
        userGroups = [...hivesData, ...swarmsData];
      }
      if (nonRepeatedBees?.length || nonRepeatedMembers?.length) {
        groupedBees = [...nonRepeatedBees, ...nonRepeatedMembers];
      }
      const [groups, searchedBees] = findBeeOrGroup(
        search,
        userGroups,
        groupedBees
      );
      for (const bee of searchedBees) {
        markersInstances.current[bee.user_id].setVisible(true);
      }
      setRenderListSearch(listSearch);
      setGroupsOrBees([...groups, ...searchedBees]);
    },
    [hivesData, swarmsData, nonRepeatedBees, nonRepeatedMembers]
  );

  const refreshListOnUpdate = useCallback(async () => {
    if (refreshList) {
      const oResponse = await getUserById(updateBeesOrMembers._id);
      if (oResponse.ok) {
        const aTemp = [...beesOnList];
        const beeIdx = aTemp.findIndex(
          bee => bee.user_id === updateBeesOrMembers._id
        );
        if (beeIdx !== -1) {
          aTemp[beeIdx].user = {
            ...aTemp[beeIdx].user,
            email: oResponse.data.email,
            full_name: oResponse.data.full_name,
            display_name: oResponse.data.display_name,
          };
        }
        setRefreshList(false);
      }
    }
  }, [refreshList, updateBeesOrMembers, beesOnList]);

  async function setupLocation() {
    if (auth?.role?.rol === 'bees') {
      setCenterMap({
        lng: auth.user?.last_position?.coordinates[0],
        lat: auth.user?.last_position?.coordinates[1],
      });
    } else {
      navigator.geolocation.getCurrentPosition(
        position => {
          setCenterMap({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        error => {
          console.log('Position rejected', error);
        }
      );
    }
  }

  useEffect(() => {
    setupLocation();
    refreshListOnUpdate();
  }, []);
  React.useEffect(() => {
    if (searchBeeInAlert) {
      findBeesOrGroups(searchBeeInAlert);
    } else {
      setGroupsOrBees([]);
    }
  }, [searchBeeInAlert, findBeesOrGroups]);
  React.useEffect(() => {
    if (searchBeeOnList) {
      findBeesOrGroups(searchBeeOnList, true);
    } else {
      setGroupsOrBees([]);
      const groupedBees = returnGroupedBees();
      for (const bee of groupedBees) {
        if (markersInstances.current[bee.user_id]) {
          markersInstances.current[bee.user_id].setVisible(true);
        }
      }
      setBeesOnList(groupedBees);
      markerClusterRef.current?.repaint();
    }
  }, [searchBeeOnList, findBeesOrGroups]);
  async function init() {
    const aBees = await getAllBees();
    const aMembers = await getAllMembers();
    const aHives = await getAllHives();
    const aSwarms = await getAllSwarms();
    setBees(aBees);
    setMembers(aMembers);
    setHivesData(aHives);
    setSwarmsData(aSwarms);
    setHomeLoading(false);
  }
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const args = {
      description: l('LOADING_BEES'),
      duration: 10,
      message: l('LOADING_BEES_TITLE'),
    };
    notification.open(args);
  }, []);

  useEffect(() => {
    const beesNotRepeated = noRepeatedBees(bees);
    const membersNotRepeated = noRepeatedBees(members);
    setNonRepeatedBees(beesNotRepeated);
    setNonRepeatedMembers(membersNotRepeated);
    if (!listFiltered) {
      let groupedBees = returnGroupedBees();
      groupedBees = groupedBees.map(oItem => ({
        ...oItem,
        visible: true,
      }));
      setMarkers(groupedBees);
      setBeesOnList(groupedBees);
    }
  }, [returnGroupedBees]);

  const onTravel = useCallback(() => {
    const { position } = overlayOptions;
    if (position?.lat && position?.lng) {
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${centerMap?.lat},${centerMap?.lng}&destination=${position?.lat},${position?.lng}&travelmode=driving`
      );
    }
  }, [userPosition, overlayOptions]);

  const locationSuccess = useCallback(pos => {
    var { latitude: lat, longitude: lng } = pos.coords;
    setUserPosition({
      lat,
      lng,
    });
  }, []);

  const showLocationError = useCallback(() => {
    notification['info']({
      description: l('LOCATION_DENIED'),
      message: l('LOCATION_DENIED_TITLE'),
    });
  }, []);

  useEffect(() => {
    if (auth.role.rol === 'admin' || auth.role.rol === 'staff') {
      if (navigator?.permissions?.query) {
        if (navigator.geolocation) {
          navigator.permissions.query({ name: 'geolocation' }).then(result => {
            if (result.state === 'granted') {
              navigator.geolocation.getCurrentPosition(locationSuccess);
            } else if (result.state === 'prompt') {
              navigator.geolocation.getCurrentPosition(
                locationSuccess,
                showLocationError,
                browserGeolocationOpts
              );
            } else if (result.state === 'denied') {
              showLocationError();
            }
            result.onchange = function () {
              console.log(result.state);
            };
          });
        }
      }
    }
  }, [locationSuccess, showLocationError]);

  const onMarkerClick = useCallback(e => {
    setOverlayOptions({
      show: true,
      position: {
        lat: e?.latLng?.lat(),
        lng: e?.latLng?.lng(),
      },
    });
  }, []);

  const onMarkerCenter = React.useCallback(
    (item, filter) => {
      const lat = item.user?.last_position?.coordinates[1];
      const lng = item.user?.last_position?.coordinates[0];
      map.setZoom(17);
      for (const id of Object.keys(markersInstances.current)) {
        if (id !== item.user_id && filter) {
          markersInstances.current[id].setVisible(false);
        }
      }
      if (item?.user) {
        markersInstances.current[item.user_id]?.setVisible(true);
        if (!lat || !lng) {
          notification['info']({
            message: l('NO_POSITION'),
            description: l('NO_POSITION_FOR_BEE'),
          });
          return;
        }
        if (filter) {
          setBeesOnList([item]);
          setListFiltered(true);
        }
        setCalloutData({
          user: item?.user,
          updated_at: item?.updated_at,
        });
        map.panTo(new google.maps.LatLng(lat, lng));
        setOverlayOptions({
          show: true,
          position: {
            lat,
            lng,
          },
        });
        setRenderListSearch(false);
        markerClusterRef.current?.repaint();
        return;
      }
      setGroupModal({
        visible: true,
        type: item?.type === 'public' ? 'swarm' : 'hive',
        data: item,
      });
      if (item?.type === 'public') {
        const oMembers = nonRepeatedMembers.filter(
          oMember => oMember.swarm_id === item?._id
        );
        if (!oMembers.length) {
          markerClusterRef.current?.repaint();
          return;
        }
        if (filter) {
          setBeesOnList(oMembers);
          setListFiltered(true);
        }
        const bounds = new window.google.maps.LatLngBounds();
        oMembers.map(oMember => {
          markersInstances.current[oMember.user_id]?.setVisible(true);
          if (oMember.user?.last_position?.coordinates) {
            bounds.extend({
              lat: oMember.user?.last_position?.coordinates[1],
              lng: oMember.user?.last_position?.coordinates[0],
            });
          }
        });
        map.fitBounds(bounds);
        setRenderListSearch(false);
        markerClusterRef.current?.repaint();
        return;
      } else {
        const oBees = nonRepeatedBees.filter(
          oBee => oBee.hive_id === item?._id
        );
        if (!oBees.length) {
          return;
        }
        if (filter) {
          setBeesOnList(oBees);
          setListFiltered(true);
        }
        const bounds = new window.google.maps.LatLngBounds();
        oBees.map(oBee => {
          markersInstances.current[oBee.user_id]?.setVisible(true);
          if (oBee.user?.last_position?.coordinates) {
            bounds.extend({
              lat: oBee.user?.last_position?.coordinates[1],
              lng: oBee.user?.last_position?.coordinates[0],
            });
          }
        });
        map.fitBounds(bounds);
        setRenderListSearch(false);
      }
      markerClusterRef.current?.repaint();
    },
    [nonRepeatedBees, nonRepeatedMembers, map]
  );

  const onShowHideMarker = item => {
    if (hideMarkersIdx.includes(item._id)) {
      setHideMarkersIdx(hideMarkersIdx.filter(row => row !== item?._id));
      markersInstances.current[item.user_id].setMap(map);
    } else {
      setHideMarkersIdx([...hideMarkersIdx, item?._id]);
      markersInstances.current[item.user_id].setMap(null);
    }
    markersInstances.current[item.user_id].setVisible(
      !markersInstances.current[item.user_id].visible
    );
    markerClusterRef.current?.repaint();
  };
  const onNotify = useCallback(async () => {
    if (!alertBodyObj.alertType) {
      notification['info']({
        message: l('NO_ALERT_TYPE_SELECTED'),
        description: l('NO_ALERT_TYPE_SELECTED_DESCRIPTION'),
      });
      return;
    }
    if (!checkboxAlertOpts.filter(option => option.checked).length) {
      notification['info']({
        message: l('NO_DESTINY_ON_ALERT'),
        description: l('NO_DESTINY_ON_ALERT_DESCRIPTION'),
      });
      return;
    }
    let alertPayload = {
      accuracy: 10,
      altitude: 0,
      date: new Date(),
      heading: 17.26,
      lat: userPosition?.lat,
      lng: userPosition?.lng,
      speed: 10.53,
      type_id: alertBodyObj.alertType,
    };
    if (alertBodyObj.alertDescription) {
      alertPayload.message = alertBodyObj.alertDescription;
    }
    const oValuesToSend = checkboxAlertOpts
      .filter(values => values.checked)
      .map(({ type, target_id }) => {
        return {
          target_id: target_id ? target_id : undefined,
          type,
        };
      });
    let alertPayloadAll = oValuesToSend.filter(oValue => oValue.type === 'all');
    let alertPayloadGroup = oValuesToSend.filter(
      oValue => oValue.type === 'hives' || oValue.type === 'swarms'
    );
    let alertPayloadUsers = oValuesToSend.filter(
      oValue => oValue.type === 'users'
    );
    alertPayload.targets = [
      ...alertPayloadAll,
      ...alertPayloadGroup,
      ...alertPayloadUsers,
    ];
    const oResponse = await createAlert(alertPayload);
    if (oResponse.ok) {
      notification['success']({
        message: l('NOTIFICATION_CREATED'),
        description: l('SUCCESS_CREATED_NOTIFICATION'),
      });
      setSearchBeeInAlert('');
      setCheckboxAlertOpts(checkboxInitOptions);
      setAlertBodyObj({
        alertDescription: '',
        alertType: '',
      });
    }
    setShowAlertModal(false);
  }, [alertBodyObj, checkboxAlertOpts]);

  const appendItemToNotify = React.useCallback(
    item => {
      if (!checkboxAlertOpts.find(oItem => oItem.value === item._id)) {
        const aTemp = [...checkboxAlertOpts];
        aTemp.push({
          label: item.user ? item.user.full_name : item.name,
          value: item._id,
          target_id: item._id,
          type: item.user
            ? 'users'
            : item.type === 'public'
            ? 'swarms'
            : 'hives',
          checked: true,
        });
        setCheckboxAlertOpts(aTemp);
      }
    },
    [checkboxAlertOpts]
  );

  const toggleCheckboxChange = useCallback(
    event => {
      const value = event.target.value;
      const aTemp = [...checkboxAlertOpts];
      aTemp.map(checkOption => {
        if (checkOption.value === value) {
          checkOption.checked = !checkOption.checked;
        }
      });
      setCheckboxAlertOpts(aTemp);
    },
    [checkboxAlertOpts]
  );

  const getSpecificUser = React.useCallback(async _id => {
    const oResponse = await getUserById(_id);
    if (oResponse.ok) {
      setUserModalData({
        user: oResponse.data,
        visible: true,
      });
    }
  }, []);

  const _handleToggleViewMarkers = () => {
    //Ocultamos uno por uno los marker
    if (hideAllMarkers) {
      setHideMarkersIdx([]);
      for (let key of Object.keys(markersInstances.current)) {
        markersInstances.current[key].setVisible(true);
      }
    } else {
      let tmpHideId = [];
      for (let key of Object.keys(markersInstances.current)) {
        tmpHideId.push(key);
        markersInstances.current[key].setVisible(false);
      }
      setHideMarkersIdx(tmpHideId);
    }
    setHideAllMarkers(!hideAllMarkers);
    markerClusterRef.current?.repaint();
  };

  const _handleCenterMap = () => {
    if (centerMap?.lat && centerMap?.lng) {
      map.panTo(new google.maps.LatLng(centerMap.lat, centerMap.lng));
    }
  };

  const _handleRenderMap = () => {
    return (
      <LoadScript googleMapsApiKey={getGoogleApiKey()}>
        <GoogleMap
          ref={mapRef}
          onLoad={onLoad}
          mapContainerStyle={MAP_CONTAINER_STYLE}
          zoom={DEFAULT_ZOOM}
          center={centerMap}
          onClick={() => {
            setOverlayOptions({
              show: false,
              position: undefined,
            });
          }}
        >
          {overlayOptions.show && (
            <OverlayView
              position={overlayOptions.position}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <Card className="overlayStyles">
                <h1>{calloutData.user?.full_name}</h1>
                <h4>{calloutData.user?.email}</h4>
                <label>
                  {l('POSITION_LAST_UPDATE')}
                  {dayjs(calloutData?.user?.updated_at).format(
                    'DD/MM/YYYY hh:mm A'
                  )}
                </label>
                <Row gutter={[10, 10]}>
                  <Col span={12}>
                    <Button
                      onClick={onTravel}
                      type="button"
                      style={{ width: '100%' }}
                    >
                      Viajar
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      onClick={() => {
                        setShowAlertModal(true);
                        setCheckboxAlertOpts([
                          ...checkboxInitOptions,
                          {
                            label: calloutData.user?.full_name,
                            value: calloutData.user?._id,
                            type: 'users',
                            target_id: calloutData.user?._id,
                            checked: true,
                          },
                        ]);
                      }}
                      type="primary"
                      style={{ width: '100%' }}
                    >
                      Notificar
                    </Button>
                  </Col>
                </Row>
              </Card>
            </OverlayView>
          )}
          <MarkerClusterer
            onLoad={ref => {
              markerClusterRef.current = ref;
              console.log('cluster', ref);
            }}
            averageCenter
            enableRetinaIcons
            maxZoom={DEFAULT_ZOOM}
            ignoreHidden
            gridSize={60}
          >
            {clusterer => {
              return (
                <>
                  {!auth.user?.last_position?.coordinates[0] &&
                    !auth.user?.last_position?.coordinates[1] && (
                      <Marker
                        icon={{
                          url: '/marker_black.png',
                          scaledSize: {
                            width: 40,
                            height: 50,
                          },
                        }}
                        onLoad={instance => {
                          markersInstances.current['current'] = instance;
                        }}
                        position={centerMap}
                        clusterer={clusterer}
                      />
                    )}

                  {markers.map(bee => {
                    const lng = bee?.user?.last_position?.coordinates[0];
                    const lat = bee?.user?.last_position?.coordinates[1];
                    const position = {
                      lat: lat ? lat : DEFAULT_LAT,
                      lng: lng ? lng : DEFAULT_LNG,
                    };

                    return (
                      <Marker
                        icon={{
                          url: `/marker_${
                            bee?.user_id === auth?.user?._id ? 'black' : 'red'
                          }.png`,
                          scaledSize: {
                            width: 40,
                            height: 50,
                          },
                        }}
                        onClick={e => {
                          setCalloutData({
                            user: bee?.user,
                            updated_at: bee?.user?.updated_at,
                          });
                          onMarkerClick(e);
                        }}
                        onLoad={instance => {
                          markersInstances.current[bee?.user_id] = instance;
                        }}
                        key={bee._id}
                        position={position}
                        clusterer={clusterer}
                      />
                    );
                  })}
                </>
              );
            }}
          </MarkerClusterer>
        </GoogleMap>
      </LoadScript>
    );
  };

  return (
    <div className="customWrapper fadeIn">
      <Modal
        centered
        title={l('MODAL_ALERT_TITLE')}
        visible={showAlertModal}
        onOk={onNotify}
        onCancel={() => {
          setShowAlertModal(false);
        }}
        confirmLoading={homeLoading}
      >
        <div>
          <Radio.Group
            onChange={e => {
              setAlertBodyObj({
                ...alertBodyObj,
                alertType: e.target.value,
              });
            }}
          >
            {alertCategories.map((alert, idx) => (
              <Radio
                key={idx}
                checked={alertBodyObj.alertType === alert._id}
                value={alert._id}
              >
                {alert.name}
              </Radio>
            ))}
          </Radio.Group>
          <div style={Styles.mt10}>
            <TextArea
              onChange={e => {
                setAlertBodyObj({
                  ...alertBodyObj,
                  alertDescription: e.target.value,
                });
              }}
              value={alertBodyObj.alertDescription}
              placeholder={l('DESCRIPTION')}
              rows={4}
            />
          </div>
          <div style={Styles.mt10}>
            <Search
              placeholder={l('SEARCH_BEE_GROUP')}
              size="large"
              onChange={e => setSearchBeeInAlert(e.target.value)}
            />
          </div>
          {groupsOrBees.length ? (
            <div style={Styles.mt10}>
              <List
                itemLayout="horizontal"
                dataSource={groupsOrBees.slice(0, 3)}
                renderItem={item => (
                  <List.Item onClick={() => appendItemToNotify(item)}>
                    <List.Item.Meta
                      title={
                        <label>
                          {item.user ? item.user.full_name : item.name}
                        </label>
                      }
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </div>
          ) : null}
          <div style={Styles.mt10}>
            {checkboxAlertOpts.map((checkOpt, idx) => (
              <div
                key={idx}
                style={{
                  margin: 10,
                  display: 'inline-block',
                }}
              >
                <input
                  type="checkbox"
                  value={checkOpt.value}
                  checked={checkOpt.checked}
                  onChange={toggleCheckboxChange}
                />
                <label
                  style={{
                    marginLeft: 10,
                  }}
                >
                  {checkOpt.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <UserModal
        visible={userModalData.visible}
        onCancel={() => {
          setUserModalData({
            user: {},
            visible: false,
          });
        }}
        okText={
          userModalData?.user?._id
            ? userModalData?.user?._id === auth?.user?._id
              ? l('UPDATE')
              : undefined
            : l('ADD')
        }
        user={userModalData?.user}
        updater={() => {
          setRefreshList(true);
          setUserModalData({
            user: {},
            visible: false,
          });
        }}
        screen="detail"
        wrapClassName={
          userModalData?.user?._id !== auth?.user?._id && 'mdl-read-only'
        }
      />
      <GroupDetailModal
        title={
          groupModal.type === 'swarm' ? l('SWARM_DETAIL') : l('BEE_DETAIL')
        }
        visible={groupModal.visible}
        apiFn={{
          updateRecord: groupModal.type === 'swarm' ? updateSwarm : updateHive,
          createRecord: () => ({}),
        }}
        description={'Para unirte a un grupo debes hacerlo desde la aplicación'}
        showMembers={
          groupModal.type === 'swarm' ||
          auth.user?._id === groupModal.data?.user_id
        }
        module={groupModal.type}
        viewOnly={auth.user?._id !== groupModal.data?.user_id}
        recordName={l('HIVE')}
        RecordForm={groupModal.type === 'hive' ? HiveForm : SwarmsForm}
        onCancel={() => {
          setGroupModal({
            visible: false,
            data: {},
          });
        }}
        item={groupModal.data}
        updater={async () => {
          await init();
          await findBeesOrGroups(searchBeeOnList, true);
          await refreshListOnUpdate();
          setGroupModal({
            visible: false,
            data: {},
          });
        }}
      />
      <Row style={styles.rowContainerStyle}>
        <Col span={6} style={{ height: '100%' }}>
          <Row className="catalog-layout" style={{ padding: 10 }}>
            <Col flex="none">
              <Row justify="space-between" gutter={10}>
                <Col span={16}>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    placeholder={l('SEARCH_BEE_GROUP')}
                    onSearch={e => setSearchBeeOnList(e)}
                    notFoundContent={null}
                    allowClear
                    onClear={() => {
                      setSearchBeeOnList('');
                    }}
                    value={searchBeeOnList}
                    onSelect={e => {
                      setSearchBeeOnList(e);
                      onMarkerCenter(
                        groupsOrBees.find(row => row._id === e),
                        true
                      );
                    }}
                    filterOption={false}
                    options={groupsOrBees.slice(0, 3).map(item => ({
                      value: item?._id,
                      label: item.user ? item.user.full_name : item.name,
                    }))}
                  />
                </Col>
                <Col flex="auto">
                  <Button
                    type="primary"
                    onClick={_handleToggleViewMarkers}
                    style={{ width: '100%' }}
                  >
                    {hideAllMarkers ? l('SHOW') : l('HIDE')}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col flex="auto" style={{ overflow: 'auto' }}>
              <List
                loading={homeLoading}
                dataSource={beesOnList}
                renderItem={item => (
                  <Card className="cardStyle">
                    <Row>
                      <Col>
                        <p className="fontBold">{item.user?.full_name}</p>
                      </Col>
                    </Row>
                    <Row>
                      {item.updated_at ? (
                        <Col>
                          <Row>
                            <Col>
                              <label className="colorGray">
                                {l('POSITION_LAST_UPDATE')}
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="colorGray">
                                {dayjs(item?.user?.updated_at).format(
                                  'DD/MM/YYYY hh:mm A'
                                )}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                      {item.user?.last_position ? (
                        <Col>
                          <Row>
                            <Col>
                              <label className="colorGray">
                                {l('COORDINATES')}
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="colorGray">{`Lat: ${item.user?.last_position?.coordinates[1]}, Lng: ${item.user?.last_position?.coordinates[0]}`}</p>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                    <Row
                      justify={
                        auth.role.rol !== 'bees'
                          ? 'space-between'
                          : 'space-around'
                      }
                    >
                      <Col>
                        <Button onClick={() => onMarkerCenter(item, false)}>
                          {l('LOCATE')}
                        </Button>
                      </Col>
                      <Col>
                        <Button onClick={() => onShowHideMarker(item)}>
                          {hideMarkersIdx.includes(item._id)
                            ? l('SHOW')
                            : l('HIDE')}
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          onClick={() => {
                            getSpecificUser(item?.user_id);
                            setUpdateBeesOrMembers({
                              type: item?.hive ? 'bees' : 'members',
                              _id: item.user_id || '',
                            });
                          }}
                        >
                          {l('DETAIL')}
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                )}
              />
            </Col>
          </Row>
        </Col>
        <Col className="map-content" span={18} style={{ height: '100%' }}>
          <div className="center-wrapper">
            <Button onClick={_handleCenterMap}>Centrar</Button>
          </div>
          {_handleRenderMap()}
        </Col>
      </Row>
    </div>
  );
};

HomeScreen.propTypes = {
  isMarkerShown: PropTypes.object,
};

export default HomeScreen;
