import React, { useState } from 'react';
import { SwarmsColumns, useColumn } from '../../Hooks/Columns.hook';
import { Avatar, Col, Modal, Popover, Row, Input, Button } from 'antd';
import Image from './../../Components/Antd/Image';
import { useI18n } from '../../Hooks/i18n.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faEllipsisV,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import CreationModal from '../../Components/Data/CreationModal';
import {
  updateSwarm,
  findSwarmsByName,
  deleteGroup,
  createSwarm,
} from '../../Services/API';
import { useFetchSwarm } from '../../Hooks/Swarms.hook';
import DynamicTable from '../../Components/Data/Table';
import SwarmsForm from '../../Components/Form/Swarm.form';
// Asset
import iconSwarm from '../../Assets/img/icon_swarm.png';
import { useAuth } from '../../Hooks/Auth.hook';

const { Search } = Input;

const SwarmScreen = () => {
  const [, l] = useI18n();
  const [, getColumn] = useColumn(SwarmsColumns);
  const [auth] = useAuth();
  const [selectedItem, setSelectedItem] = useState({});
  const [modal, setModal] = useState(false);
  const [swarms, loading, , change, update] = useFetchSwarm();
  const [search, setSearch] = useState('');
  const [groupIdToDelete, setGroupIdToDelete] = useState('');
  const [showGroupModalDelete, setShowGroupModalDelete] = useState(false);
  const [searchedPressed, setSearchedPressed] = useState(false);
  const [searchedSwarms, setSearchedSwarms] = useState([]);
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const columns = [
    {
      ...getColumn('image'),
      render(e = '') {
        return e ? (
          <Avatar size={38} src={<Image src={e} />} />
        ) : (
          <img width={40} src={iconSwarm} />
        );
      },
    },
    {
      ...getColumn('name'),
      width: 170,
      render(text) {
        return (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {text}
          </div>
        );
      },
    },
    {
      ...getColumn('description'),
      width: 170,
      render(text) {
        return (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {text}
          </div>
        );
      },
    },
    {
      ...getColumn('type'),
      render(e = '') {
        return e.substr(0, 1).toLocaleUpperCase() + e.substr(1, e.length);
      },
    },
    {
      ...getColumn('status'),
      width: 150,
      filters: [
        { text: l('ACTIVE'), value: 1 },
        { text: l('INACTIVE'), value: 0 },
      ],
      onFilter: (value, record) => record.status.toString().includes(value),
      render(e) {
        if (e) {
          return l('ACTIVE');
        }
        return l('INACTIVE');
      },
    },
    {
      title: '',
      dataIndex: '_id',
      render(data, row) {
        const [triggerVisible, setTriggerVisible] = useState(false);
        return (
          <Popover
            trigger="click"
            onVisibleChange={e => {
              setTriggerVisible(e);
            }}
            visible={triggerVisible}
            content={
              <div className="popover-common">
                <ul>
                  <li
                    onClick={() => {
                      setSelectedItem({ ...row });
                      setModal(true);
                      setTriggerVisible(false);
                    }}
                  >
                    {row?.user_id === auth?.user?._id ? l('EDIT') : l('VIEW')}
                  </li>
                  {row?.user_id === auth?.user?._id && (
                    <>
                      <li
                        onClick={() => {
                          setShowGroupModalDelete(true);
                          setGroupIdToDelete(row?._id);
                          setTriggerVisible(false);
                        }}
                      >
                        {l('DELETE')}
                      </li>
                      <li
                        className={row.status ? 'danger' : 'success'}
                        onClick={() => {
                          Modal.confirm({
                            title: row.status
                              ? l('DISABLE_SWARM')
                              : l('ENABLE_SWARM'),
                            onOk: async () => {
                              let response = await updateSwarm(data, {
                                status: row.status ? 0 : 1,
                              });
                              if (response.ok) {
                                update();
                              }
                            },
                          });
                        }}
                      >
                        {row.status ? (
                          <>
                            {l('DISABLE')}
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="anticon"
                            />
                          </>
                        ) : (
                          <>
                            {l('ENABLE')}
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="anticon"
                            />
                          </>
                        )}
                      </li>
                    </>
                  )}
                </ul>
              </div>
            }
            placement="bottom"
          >
            <div style={{ width: 30 }}>
              <FontAwesomeIcon icon={faEllipsisV} className="anticon trigger" />
            </div>
          </Popover>
        );
      },
    },
  ];

  const deleteGroupAction = React.useCallback(async () => {
    setLoadingGroup(true);
    const oResponse = await deleteGroup({
      action: 'clean-group',
      target_id: groupIdToDelete,
      service: 'swarms',
    });
    if (oResponse.ok) {
      update();
      setShowGroupModalDelete(false);
    }
    setLoadingGroup(false);
  }, [groupIdToDelete]);

  const onSearch = React.useCallback(async () => {
    if (search) {
      setLoadingGroup(true);
      setSearchedPressed(true);
      const oResponse = await findSwarmsByName(search);
      if (oResponse.ok) {
        setSearchedSwarms(oResponse.data.data);
        setLoadingGroup(false);
      }
    }
  }, [search]);

  React.useEffect(() => {
    if (!search) {
      setSearchedPressed(false);
    }
  }, [search]);

  return (
    <div className="wrapper">
      <CreationModal
        visible={modal}
        apiFn={{
          updateRecord: updateSwarm,
          createRecord: createSwarm,
        }}
        recordName={l('SWARM')}
        RecordForm={SwarmsForm}
        onCancel={() => {
          setModal(false);
          setIsCreating(false);
          setSelectedItem({});
        }}
        viewOnly={
          isCreating
            ? !isCreating
            : auth.user?._id !== selectedItem.user_id &&
              auth.role.rol !== 'admin'
        }
        item={selectedItem}
        updater={() => {
          update();
          setModal(false);
          setIsCreating(false);
          setSelectedItem({});
        }}
        module="swarm"
        showMembers={!isCreating}
      />
      <Modal
        title={l('MODAL_DELETE_TITLE')}
        visible={showGroupModalDelete}
        onOk={deleteGroupAction}
        onCancel={() => {
          setShowGroupModalDelete(false);
        }}
        centered
        confirmLoading={loadingGroup}
      >
        <p>{l('SURE_TO_REMOVE_GROUP')}</p>
        <p>{l('REMOVE_GROUP_DESCRIPTION')}</p>
      </Modal>
      <div className="content">
        <Row className="catalog-layout">
          <Col flex="none">
            <Row justify="space-between" className="title">
              <Col>
                <h3>
                  {swarms.total} {l('SWARMS')}
                </h3>
                <Button
                  type="primary"
                  onClick={() => {
                    setIsCreating(true);
                    setModal(true);
                  }}
                  icon={
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="anticon trigger"
                    />
                  }
                >
                  {l('ADD')}
                </Button>
              </Col>
              <Col>
                <Search
                  placeholder={l('GROUP_NAME_PLACEHOLDER')}
                  size="large"
                  onChange={e => setSearch(e.target.value)}
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
              </Col>
            </Row>
          </Col>
          <Col flex="auto">
            <div className="dynamic-table">
              <DynamicTable
                columns={columns}
                dataSource={
                  search && searchedPressed ? searchedSwarms : swarms.data
                }
                loading={loading || loadingGroup}
                rowKey={e => e._id}
                pagination={{
                  total:
                    search && searchedPressed
                      ? searchedSwarms.length
                      : swarms.total,
                  current: swarms.params.skip / 10 + 1,
                  onChange: e => change(swarms.params.queries, (e - 1) * 10),
                  pageSizeOptions: [10],
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SwarmScreen;
