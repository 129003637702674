import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select } from 'antd';
import { useI18n } from '../../Hooks/i18n.hook';
const BrandForm = ({ formRef, onSubmit, ...props }) => {
  const [, l] = useI18n();
  return (
    <Form form={formRef} onFinish={onSubmit} layout="vertical" {...props}>
      <Form.Item
        label={l('NAME')}
        name="name"
        rules={[{ required: true, message: l('_fieldRequired') }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={l('DESCRIPTION')} name="description">
        <Input />
      </Form.Item>
      <Form.Item label={l('STATUS')} name="status">
        <Select>
          <Select.Option value={1}>{l('ACTIVE')}</Select.Option>
          <Select.Option value={0}>{l('INACTIVE')}</Select.Option>
        </Select>
      </Form.Item>
    </Form>
  );
};
BrandForm.propTypes = {
  formRef: PropTypes.any,
  onSubmit: PropTypes.func,
};
export default BrandForm;
