import React, { useState } from 'react';
import { Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';
//Hooks
import { useAuth } from '../../Hooks/Auth.hook';
import { useMobile } from '../../Hooks/Responsive.hook';
import { useDrawer } from '../../Hooks/App.hook';
import { useI18n } from '../../Hooks/i18n.hook';

const HeaderDashboard = () => {
  const [, , logOut] = useAuth();
  const [, label] = useI18n();
  const [isMobile] = useMobile();
  const [, setVisible] = useDrawer(false);
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Modal
        title={label('LOGOUT_QUESTION')}
        visible={modalVisible}
        onOk={logOut}
        onCancel={() => setModalVisible(false)}
        okText={label('CONFIRM')}
        cancelText={label('CANCEL')}
      />
      {isMobile && (
        <header className="header-dashboard">
          <FontAwesomeIcon
            icon={faBars}
            className="anticon trigger"
            onClick={() => setVisible(true)}
          />
          <div className="items">
            <div className="logout-trigger">
              <FontAwesomeIcon
                icon={faSignOutAlt}
                onClick={() => setModalVisible(true)}
                style={{ cursor: 'pointer' }}
              >
                Cerrar sesión
              </FontAwesomeIcon>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default HeaderDashboard;
