const MAP_SETTINGS = {
  MAP_CONTAINER_STYLE: {
    height: '100%',
    width: '100%',
  },
  DEFAULT_CENTER: {
    lat: 6.275809475471447,
    lng: -75.59617025089038,
  },
  DEFAULT_ZOOM: 21,
};

export default MAP_SETTINGS;
