import React from 'react';
import propTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import HomeScreen from '../Screens/Home';
import UsersScreen from '../Screens/Users';
import FileScreen from '../Screens/Files';
import SettingsScreen from '../Screens/Settings';
import BrandScreen from '../Screens/Brands';
import ColorScreen from '../Screens/Colors';
import CategoriesScreen from '../Screens/Categories';
import ClassesScreen from '../Screens/Classes';
import ModelsScreen from '../Screens/Models';
import FuelScreen from '../Screens/Fuels';
import HivesScreens from '../Screens/Hives';
import PermissionScreen from '../Screens/Permissions';
import SwarmScreen from '../Screens/Swarms';

const SubRouter = ({ match: { url }, locations }) => {
  return (
    <Switch>
      <Route
        path={`${url}`}
        component={() => <HomeScreen />}
        location={locations}
        exact
      />
      <Route
        path={`${url}/users`}
        component={UsersScreen}
        location={locations}
        exact
      />
      <Route
        path={`${url}/files`}
        component={FileScreen}
        location={locations}
        exact
      />
      <Route
        path={`${url}/catalogs/brands`}
        component={BrandScreen}
        location={locations}
      />
      <Route
        path={`${url}/groups/swarms`}
        component={SwarmScreen}
        location={locations}
      />
      <Route
        path={`${url}/catalogs/colors`}
        component={ColorScreen}
        location={locations}
      />
      <Route
        path={`${url}/groups/hives`}
        component={HivesScreens}
        location={locations}
      />
      <Route
        path={`${url}/catalogs/categories`}
        component={CategoriesScreen}
        location={locations}
      />
      <Route
        path={`${url}/catalogs/classes`}
        component={ClassesScreen}
        location={locations}
      />
      <Route
        path={`${url}/catalogs/models`}
        component={ModelsScreen}
        location={locations}
      />
      <Route
        path={`${url}/catalogs/fuels`}
        component={FuelScreen}
        location={locations}
      />
      <Route
        path={`${url}/system/settings`}
        component={SettingsScreen}
        location={locations}
      />
      <Route
        path={`${url}/system/permissions`}
        component={PermissionScreen}
        location={locations}
      />
    </Switch>
  );
};
SubRouter.propTypes = {
  match: propTypes.shape({
    url: propTypes.string.isRequired,
  }),
  locations: propTypes.object,
};
export default withRouter(SubRouter);
