import React from 'react';

import logo from '../../Assets/img/logo.png';
import LoginForm from '../../Components/Form/Login.form';

import { useAuth } from '../../Hooks/Auth.hook';
import { useI18n } from '../../Hooks/i18n.hook';

const LoginScreen = () => {
  const [{ loading }, login] = useAuth();
  const onSubmit = async ({ username, password }) => {
    await login(username, password);
  };
  const [, l] = useI18n();
  return (
    <div className="login-screen slideInLeft">
      <div className="cover"></div>
      <div className="wrapper">
        <div className="logo">
          <img src={logo} />
        </div>
        <LoginForm onSubmit={onSubmit} loading={loading} />
      </div>
      <div className="version">
        {l('SITE_VERSION')}: {process.env.REACT_APP_VERSION || '1.0.0'}
      </div>
    </div>
  );
};

export default LoginScreen;
