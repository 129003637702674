import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../Assets/img/logo-w.png';
import logoShort from '../../Assets/img/logo-w-single.png';

const Logo = ({ collapsed }) => {
  return (
    <div className="logo-wrapper">
      {!collapsed ? (
        <img className="logo" src={logo} />
      ) : (
        <img className="logo" src={logoShort} />
      )}
    </div>
  );
};

Logo.propTypes = {
  collapsed: PropTypes.bool,
};

export default Logo;
