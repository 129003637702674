import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

const DynamicTable = ({ columns, dataSource, ...props }) => {
  const tableProps = {
    columns,
    dataSource,
    ...props,
  };
  return <Table {...tableProps} />;
};

DynamicTable.propTypes = {
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.array,
};
export default DynamicTable;
