import React from 'react';
import {
  FilePdfTwoTone,
  FileTextTwoTone,
  FileWordTwoTone,
  FileZipTwoTone,
  FolderTwoTone,
  PictureTwoTone,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { CornerLeftUp } from 'react-feather';

const FileIcon = ({ fileType, twoToneColor, ...props }) => {
  switch (fileType) {
    case 'pdf':
      return <FilePdfTwoTone {...props} twoToneColor="#FF4D4F" />;
    case 'doc':
    case 'docx':
      return <FileWordTwoTone {...props} twoToneColor="#1890FF" />;
    case 'zip':
    case 'rar':
      return <FileZipTwoTone {...props} />;
    case 'folder':
      return <FolderTwoTone {...props} twoToneColor="#f75757" />;
    case 'arrow-l':
      return <CornerLeftUp {...props} />;
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <PictureTwoTone {...props} />;
    default:
      return <FileTextTwoTone {...props} twoToneColor={twoToneColor} />;
  }
};
FileIcon.propTypes = {
  fileType: PropTypes.string,
  twoToneColor: PropTypes.any,
};
export default FileIcon;
