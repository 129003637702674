import { Layout } from 'antd';
import React from 'react';
// Components
import DefaultContainer from '../../Components/Layout/DefaultContainer';
import ModalError from '../../Components/Layout/ModalError';
import Sidebar from '../../Components/Layout/Sidebar';

const DashboardScreen = () => {
  return (
    <Layout className="dashboard fadeIn">
      <ModalError />
      <Sidebar />
      <DefaultContainer />
    </Layout>
  );
};

export default DashboardScreen;
